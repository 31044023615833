import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from 'environments/environment';
import { StoreService } from 'app/store/store.service';

import {
  Account,
  Address,
  AliasList,
  Api,
  Asset,
  AssetList,
  Balance,
  BlockList, BtcKeys, ChainTimestamp, PlaceOrderArgs,
  Transaction,
  TransactionId,
  TransactionList,
  TransactionMiningSubtype,
  TransactionType,
  UnconfirmedTransactionList
} from '@fruitsjs/core';
import { decryptAES, encryptAES, encryptMessage, generateMasterKeys, hashSHA256, Keys } from '@fruitsjs/crypto';
import { Amount } from '@fruitsjs/util';
import { HttpClientFactory, HttpError } from '@fruitsjs/http';
import { ApiService } from '../../api.service';
import { I18nService } from 'app/layout/components/i18n/i18n.service';
import { NetworkService } from '../../network/network.service';
import { KeyDecryptionException } from '../../util/exceptions/KeyDecryptionException';
import {AddressPrefix, BnbKeys, EthKeys, TronKeys} from '@fruitsjs/core/src';
import { adjustLegacyAddressPrefix } from '../../util/adjustLegacyAddressPrefix';
import { TradeList } from '@fruitsjs/core/src/typings/tradeList';
import { TransactionIdList } from '@fruitsjs/core/out/typings/transactionIdList';
import { EthService } from '../../multi-coin/eth.service';
import { BtcService } from '../../multi-coin/btc.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {constants} from '../../constants';
import {AccountTransferService} from '../../account-transfer.service';
import {TronService} from "../../multi-coin/tron.service";
import { BnbService } from 'app/multi-coin/bnb.service';

interface SetAccountInfoRequest {
  name: string;
  description: string;
  deadline: number;
  feePlanck: string;
  pin: string;
  keys: Keys;
}

interface SetRewardRecipientRequest {
  recipientId: string;
  deadline: number;
  feePlanck: string;
  pin: string;
  keys: Keys;
}

interface SetAliasRequest {
  aliasName: string;
  aliasURI: string;
  deadline: number;
  feeNQT: string;
  pin: string;
  keys: Keys;
}

interface SetCommitmentRequest {
  amountPlanck: string;
  feePlanck: string;
  pin: string;
  keys: Keys;
  isRevoking: boolean;
}

interface IssueAssetRequest {
  amountPlanck: string;
  decimals: number;
  description: string;
  name: string;
  quantity: string;
  keys: Keys;
  attachment: any;
  deadline: number;
  pin: string;
  noteToSelf: any;
  refTransactionHash: string;
  advanced: boolean;
  icon: string;
}


@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public accountSubject: BehaviorSubject<Account> = new BehaviorSubject(undefined);
  public currentAccount: BehaviorSubject<Account> = new BehaviorSubject(undefined);
  private api: Api;
  private transactionsSeenInNotifications: string[] = [];
  private accountPrefix: AddressPrefix.MainNet | AddressPrefix.TestNet;

  marketUrl = environment.isMainNet ? constants.MARKET_MAIN : constants.MARKET_TEST;
  networkUrl = constants.nodes[0].address;

  constructor(private storeService: StoreService,
              private networkService: NetworkService,
              private apiService: ApiService,
              private i18nService: I18nService,
              private ethService: EthService,
              private btcService: BtcService,
              private tronService: TronService,
              private bnbService: BnbService,
              private http: HttpClient,
              private accountTransferService: AccountTransferService) {
    this.storeService.settings.subscribe(() => {
      this.api = this.apiService.api;
    });

    this.networkService.isMainNet$.subscribe(() => {
      this.accountPrefix = this.networkService.isMainNet() ? AddressPrefix.MainNet : AddressPrefix.TestNet;
    });
  }

  public verifyAccount(account: Account, isImported: boolean): void {
    if (isImported) {
      this.http.get(this.networkUrl + '/fruits?requestType=getCurrentStatusAccount&account=' + account.account).toPromise();
    } else {
      this.http.get(this.networkUrl + '/fruits?requestType=getStatusAccount&account=' + account.account).toPromise();
    }

  }

  public setCurrentAccount(account: Account): void {
    this.currentAccount.next(account);
  }

  public setAccountSubject(account: Account): void {
    this.accountSubject.next(account);
  }

  public async placeAskOrder(placeOrderArg: PlaceOrderArgs): Promise<TransactionId> {
    return this.api.asset.placeAskOrder(placeOrderArg);
  }

  public async placeBidOrder(placeOrderArg: PlaceOrderArgs): Promise<TransactionId> {
    return this.api.asset.placeBidOrder(placeOrderArg);
  }

  public async getAddedCommitments(account: Account): Promise<TransactionList> {
    return this.api.account.getAccountTransactions({
      accountId: account.account,
      type: TransactionType.Mining,
      subtype: TransactionMiningSubtype.AddCommitment,
      includeIndirect: false,
    });
  }

  public async getLatestAccountBlocks(account: Account): Promise<BlockList> {
    return this.api.account.getLatestAccountBlock({
      accountId: account.account,
      includeTransactions: false,
    });
  }


  public async getAccountTransactions(
    accountId: string,
    firstIndex?: number,
    lastIndex?: number,
    numberOfConfirmations?: number,
    type?: number,
    subtype?: number
  ): Promise<TransactionList> {

    const args = {
      accountId,
      firstIndex,
      lastIndex,
      numberOfConfirmations,
      type,
      subtype,
    };
    try {
      const transactions = await this.api.account.getAccountTransactions({
        ...args,
        includeIndirect: true
      });
      return Promise.resolve(transactions);
    } catch (e) {
      const EC_INVALID_ARG = 4;
      if (e.data.errorCode === EC_INVALID_ARG) {
        return await this.api.account.getAccountTransactions(args);
      } else {
        throw e;
      }
    }
  }

  public getAccountTransactionIds( accountId: string,
                                         firstIndex?: number,
                                         lastIndex?: number,
                                         numberOfConfirmations?: number,
                                         type?: number,
                                         subtype?: number,
                                         timestamp?: string): Promise<TransactionIdList> {
    const args = {accountId, timestamp, firstIndex, lastIndex, numberOfConfirmations, type, subtype};
    return this.api.account.getAccountTransactionIds(args);
  }

  public getTransaction(id: string): Promise<Transaction> {
    return this.api.transaction.getTransaction(id);
  }

  public getTime(): Promise<ChainTimestamp> {
    return this.api.network.getTime();
  }

  public getTrades(assetId, accountId?, firstIndex?, lastIndex?, includeAssetInfo?): Promise<TradeList> {
    return this.api.asset.getTrades({
      assetId,
      accountId: accountId || undefined,
      firstIndex: firstIndex || undefined,
      lastIndex: lastIndex || undefined,
      includeAssetInfo: includeAssetInfo || undefined
    });
  }

  public async transferAsset(assetId, qty, recipientId, pin, keys, fee, deadline): Promise<TransactionId> {
    const senderPrivateKey = this.getPrivateKey(keys, pin);
    return this.api.asset.transferAsset({
      asset: assetId,
      quantity: qty,
      recipientId: recipientId,
      deadline: deadline,
      feePlanck: fee,
      senderPrivateKey: senderPrivateKey,
      senderPublicKey: keys.publicKey
    });
  }

  getTransferFee = async (tokenId) => {
    const response = await fetch(`${this.api}/fruits?requestType=getTransferFee&token=${tokenId}`);
    return await response.json();
  }

  public generateSendTransactionQRCodeAddress(
    id: string,
    amountNQT?: number,
    feeSuggestionType?: string,
    feeNQT?: number,
    immutable?: boolean): Promise<string> {
    return this.api.account.generateSendTransactionQRCodeAddress(
      id,
      amountNQT,
      feeSuggestionType,
      feeNQT,
      immutable
    );
  }

  public getAlias(name: string): Promise<any> {
    return this.api.alias.getAliasByName(name);
  }

  public getAliases(id: string): Promise<AliasList> {
    return this.api.account.getAliases(id);
  }

  public getAsset(id: string): Promise<Asset> {
    return this.api.asset.getAsset(id);
  }

  public getAssets(id: number): Promise<AssetList> {
    return this.api.asset.getAllAssets(id);
  }

  public getAllAssets(): Promise<AssetList> {
    return this.api.asset.getAllAssets();
  }

  // tslint:disable-next-line:max-line-length
  public issueAssets({amountPlanck, decimals, description, name, quantity, keys, attachment, deadline, pin, noteToSelf, refTransactionHash, advanced, icon}: IssueAssetRequest): Promise<TransactionId> {
    const senderPrivateKey = this.getPrivateKey(keys, pin);
    let encryptData;
    let encryptMess;
    let encryptNonce;
    let isText;
    let refHash;

    if (advanced) {
      if (noteToSelf) {
        encryptData = encryptMessage(noteToSelf, keys.publicKey, senderPrivateKey);
        encryptMess = encryptData.data;
        encryptNonce = encryptData.nonce;
        isText = true;
      }
      refHash = refTransactionHash;
    }

    return this.api.asset.issueAsset({
      amountPlanck,
      decimals,
      description,
      name,
      quantity,
      senderPublicKey: keys.publicKey,
      senderPrivateKey,
      attachment: attachment,
      deadline: deadline,
      encryptToSelfMessageData: encryptMess,
      encryptToSelfMessageNonce: encryptNonce,
      messageToEncryptToSelfIsText: isText,
      referenceTransactionHash: refHash,
      icon
    });
  }

  public setAlias({ aliasName, aliasURI, feeNQT, deadline, pin, keys }: SetAliasRequest): Promise<TransactionId> {
    const senderPrivateKey = this.getPrivateKey(keys, pin);
    return this.api.account.setAlias(aliasName, aliasURI, feeNQT, keys.publicKey, senderPrivateKey, deadline);
  }

  private getPrivateKey(keys, pin): string {
    try {
      const privateKey = decryptAES(keys.signPrivateKey, hashSHA256(pin));
      if (!privateKey) {
        throw new Error('Key Decryption Exception');
      }
      return privateKey;
    } catch (e) {
      throw new KeyDecryptionException();
    }
  }

  public getAccountBalance(id: string): Promise<Balance> {
    return this.api.account.getAccountBalance(id);
  }

  public getUnconfirmedTransactions(id: string): Promise<UnconfirmedTransactionList> {
    return this.api.account.getUnconfirmedAccountTransactions(id);
  }

  public async getAccount(accountId: string): Promise<Account> {
    const supportsPocPlus = await this.apiService.supportsPocPlus();
    const includeCommittedAmount = supportsPocPlus || undefined;
    const account = await this.api.account.getAccount({
      accountId,
      includeCommittedAmount,
    });

    return adjustLegacyAddressPrefix(account);
  }

  public getCurrentAccount(): Promise<Account> {
    return Promise.resolve(this.currentAccount.getValue());
  }

  public setAccountInfo({ name, description, feePlanck, deadline, pin, keys }: SetAccountInfoRequest): Promise<TransactionId> {
    const senderPrivateKey = this.getPrivateKey(keys, pin);
    return this.api.account.setAccountInfo({
      name,
      description,
      feePlanck,
      senderPrivateKey,
      senderPublicKey: keys.publicKey,
      deadline
    });
  }

  public setRewardRecipient({ recipientId, feePlanck, deadline, pin, keys }: SetRewardRecipientRequest): Promise<TransactionId> {
    const senderPrivateKey = this.getPrivateKey(keys, pin);
    return this.api.account.setRewardRecipient({
      recipientId,
      senderPrivateKey,
      senderPublicKey: keys.publicKey,
      deadline,
      feePlanck,
    });
  }

  public async getRewardRecipient(recipientId: string): Promise<Account | null> {
    const { rewardRecipient } = await this.api.account.getRewardRecipient(recipientId);
    return rewardRecipient
      ? this.api.account.getAccount({ accountId: rewardRecipient })
      : null;
  }

  public setCommitment({ amountPlanck, feePlanck, pin, keys, isRevoking }: SetCommitmentRequest): Promise<TransactionId> {
    const senderPrivateKey = this.getPrivateKey(keys, pin);

    const args = {
      amountPlanck,
      senderPrivateKey,
      senderPublicKey: keys.publicKey,
      feePlanck,
    };

    return isRevoking
      ? this.api.account.removeCommitment(args)
      : this.api.account.addCommitment(args);
  }

  public createActiveAccount({ passphrase, pin = '' }): Promise<Account> {
    return new Promise(async (resolve, reject) => {
      const account: Account = new Account();
      // import active account
      account.type = 'active';
      account.confirmed = false;
      const keys = generateMasterKeys(passphrase);
      const encryptedKey = encryptAES(keys.signPrivateKey, hashSHA256(pin));
      const encryptedSignKey = encryptAES(keys.agreementPrivateKey, hashSHA256(pin));

      account.keys = {
        publicKey: keys.publicKey,
        signPrivateKey: encryptedKey,
        agreementPrivateKey: encryptedSignKey
      };
      account.pinHash = hashSHA256(pin + keys.publicKey);

      const address = Address.fromPublicKey(keys.publicKey, this.accountPrefix);
      account.account = address.getNumericId();
      account.accountRS = address.getReedSolomonAddress();
      const settings = await this.storeService.getSettings();
      account.multiWallet = settings.isMultiWallet;
      if (settings.isMultiWallet) {
        const ethKeys: EthKeys = await this.ethService.generateKey(passphrase);
        const btcKeys: BtcKeys = await this.btcService.createWallet(passphrase);
        const tronKeys: TronKeys = await this.tronService.generateAccount(passphrase, pin);
        const bnbKeys: BnbKeys = await this.bnbService.generateKey(passphrase);
        account.multiKeys = {
          passphrase: '',
          eth: ethKeys,
          btc: btcKeys,
          tron: tronKeys,
          bnb: bnbKeys
        };
      }
      account.encryptedPassphrase = encryptAES(passphrase, hashSHA256(pin));

      await this.selectAccount(account);
      const savedAccount = await this.synchronizeAccount(account);
      resolve(savedAccount);
    });
  }

  public quickCreateActiveAccount({ passphrase, pin = '' }): Promise<Account> {
    return new Promise(async (resolve, reject) => {
      const account: Account = new Account();
      // import active account
      account.type = 'active';
      account.confirmed = false;
      const keys = generateMasterKeys(passphrase);
      const encryptedKey = encryptAES(keys.signPrivateKey, hashSHA256(pin));
      const encryptedSignKey = encryptAES(keys.agreementPrivateKey, hashSHA256(pin));

      account.keys = {
        publicKey: keys.publicKey,
        signPrivateKey: encryptedKey,
        agreementPrivateKey: encryptedSignKey
      };
      account.pinHash = hashSHA256(pin + keys.publicKey);

      const address = Address.fromPublicKey(keys.publicKey, this.accountPrefix);
      account.account = address.getNumericId();
      account.accountRS = address.getReedSolomonAddress();
      const settings = await this.storeService.getSettings();
      account.multiWallet = settings.isMultiWallet;
      if (settings.isMultiWallet) {
        account.multiKeys = {
          passphrase: '',
          eth: await this.ethService.generateKey(passphrase),
          btc: await this.btcService.createWallet(passphrase),
          tron: await this.tronService.generateAccount(passphrase, pin),
          bnb: await this.bnbService.generateKey(passphrase),
        };
      }
      account.encryptedPassphrase = encryptAES(passphrase, hashSHA256(pin));

      resolve(account);
    });
  }

  public async createOfflineAccount(reedSolomonAddress: string): Promise<Account> {
    const account: Account = new Account();
    const address = Address.fromReedSolomonAddress(reedSolomonAddress);
    const existingAccount = await this.storeService.findAccount(address.getNumericId());
    if (existingAccount === undefined) {
      // import offline account
      account.type = 'offline';
      account.confirmed = false;
      account.accountRS = reedSolomonAddress;
      account.account = address.getNumericId();
      await this.selectAccount(account);
      return this.synchronizeAccount(account);
    } else {
      throw new Error('Address already imported!');
    }
  }

  public removeAccount(account: Account): Promise<boolean> {
    return this.storeService.removeAccount(account).catch(error => error);
  }

  public selectAccount(account: Account): Promise<Account> {
    return new Promise((resolve, reject) => {
      this.storeService.selectAccount(account)
        .then(acc => {
          this.synchronizeAccount(acc);
        });
      this.setCurrentAccount(account);
      resolve(account);
    });
  }

  public synchronizeAccount(account: Account): Promise<Account> {
    return new Promise(async (resolve, reject) => {
      await this.syncAccountDetails(account);
      await this.syncAccountTransactions(account);
      await this.syncAccountUnconfirmedTransactions(account);
      await this.syncAccountNewTransferredAccount(account);
      await this.syncAccountMultiWallet(account);
      this.storeService.saveAccount(account).catch(reject);
      resolve(account);
    });
  }

  public isNewTransaction(transactionId: string): boolean {
    return (!this.transactionsSeenInNotifications[transactionId]);
  }

  public sendNewTransactionNotification(transaction: Transaction): void {


    // TODO: create a notification factory according the type and show proper notifications
    if (transaction.type !== TransactionType.Payment) {
      return;
    }

    this.transactionsSeenInNotifications[transaction.transaction] = true;
    const incoming = transaction.recipient === this.currentAccount.value.account;
    const totalAmount = Amount.fromPlanck(transaction.amountNQT).add(Amount.fromPlanck(transaction.feeNQT));

    let header = '';
    let body = '';
    if (incoming) {
      // Account __a__ got __b__ from __c__
      header = this.i18nService.getTranslation('youve_got_fruits');
      body = this.i18nService.getTranslation('youve_got_from')
        .replace('__a__', transaction.recipientRS)
        .replace('__b__', totalAmount.toString())
        .replace('__c__', transaction.senderRS);
    } else {
      // Account __a__ sent __b__ to __c__
      header = this.i18nService.getTranslation('you_sent_fruits');
      body = this.i18nService.getTranslation('you_sent_to')
        .replace('__a__', transaction.senderRS)
        .replace('__b__', totalAmount.toString())
        .replace('__c__', transaction.recipientRS);
    }

    // @ts-ignore
    return window.Notification && new window.Notification(header, { body, title: 'Fruits Eco-Blockchain'});
  }

  private async syncAccountUnconfirmedTransactions(account: Account): Promise<void> {
    try {
      const unconfirmedTransactionsResponse = await this.getUnconfirmedTransactions(account.account);
      account.transactions = unconfirmedTransactionsResponse.unconfirmedTransactions
        .sort((a, b) => a.timestamp > b.timestamp ? -1 : 1)
        .concat(account.transactions);

      // @ts-ignore - Send notifications for new transactions
      if (window.Notification) {
        unconfirmedTransactionsResponse.unconfirmedTransactions
          .sort((a, b) => a.timestamp > b.timestamp ? -1 : 1)
          .filter(({ transaction }) => this.isNewTransaction(transaction))
          .map((transaction) => this.sendNewTransactionNotification(transaction));
      }
    } catch (e) {
      console.log(e);
    }
  }

  private async syncAccountTransactions(account: Account): Promise<void> {
    try {
      const transactionList = await this.getAccountTransactions(account.account, 0, 500);
      account.transactions = transactionList.transactions;
    } catch (e) {
      account.transactions = [];
    }
  }

  private async syncAccountDetails(account: Account): Promise<void> {
    try {
      const remoteAccount = await this.getAccount(account.account);
      // Only update what you really need...
      // ATTENTION: Do not try to iterate over all keys and update then
      // It will fail :shrug
      account.name = remoteAccount.name;
      account.description = remoteAccount.description;
      account.assetBalances = remoteAccount.assetBalances;
      account.unconfirmedAssetBalances = remoteAccount.unconfirmedAssetBalances;
      account.committedBalanceNQT = remoteAccount.committedBalanceNQT;
      account.balanceNQT = remoteAccount.balanceNQT;
      account.unconfirmedBalanceNQT = remoteAccount.unconfirmedBalanceNQT;
      account.accountRSExtended = remoteAccount.accountRSExtended;
      // @ts-ignore
      account.confirmed = !!remoteAccount.publicKey;
    } catch (e) {
      account.confirmed = false;
      // console.log(e);
    }
  }

  public async syncAccountInfoDetails(account: Account): Promise<Account> {
    try {
      const remoteAccount = await this.getAccount(account.account);
      // Only update what you really need...
      // ATTENTION: Do not try to iterate over all keys and update then
      // It will fail :shrug
      account.name = remoteAccount.name;
      account.description = remoteAccount.description;
      account.assetBalances = remoteAccount.assetBalances;
      account.unconfirmedAssetBalances = remoteAccount.unconfirmedAssetBalances;
      account.committedBalanceNQT = remoteAccount.committedBalanceNQT;
      account.balanceNQT = remoteAccount.balanceNQT;
      account.unconfirmedBalanceNQT = remoteAccount.unconfirmedBalanceNQT;
      account.accountRSExtended = remoteAccount.accountRSExtended;
      // @ts-ignore
      account.confirmed = !!remoteAccount.publicKey;
      return account;
    } catch (e) {
      account.confirmed = false;
      return account;
      // console.log(e);
    }
  }

  private async syncAccountNewTransferredAccount(account: Account): Promise<void> {
    try {
      const accountTransfer: any = await this.accountTransferService.getNewAccount(account.account).toPromise().catch((e) => {
        account.oldAccount = null;
      });
      if (accountTransfer.errorCode === 0 && accountTransfer.result) {
        account.oldAccount = accountTransfer.result.oldAccount;
      } else {
        account.oldAccount = null;
      }
    } catch (e) {
      account.oldAccount = null;
    }
  }

  private async syncAccountMultiWallet(account: Account): Promise<void> {
    if (account.multiWallet) {
      if (account.multiKeys && account.multiKeys.eth && account.multiKeys.eth.address) {
        account.multiKeys.eth.balance = await this.ethService.getBalance(account.multiKeys.eth.address);
        const usdtBalance = await this.ethService.getUSDTBalance(account.multiKeys.eth.address);
        account.multiKeys.eth.usdtBalance = Amount.fromPlanck(usdtBalance).getRaw().dividedBy(Math.pow(10, constants.MAX_LENGTH_USDT)).toString();
      }
      if (account.multiKeys && account.multiKeys.btc && account.multiKeys.btc.address) {
        const balance = await this.btcService.getTotalAvailableAmount(account.multiKeys.btc.address) / constants.btcSATOSHI;
        account.multiKeys.btc.balance = Amount.fromPlanck(balance).getRaw().toString();
      }

      // sync trx usdt
      if (account.multiKeys && account.multiKeys.tron && account.multiKeys.tron.address) {
        account.multiKeys.tron.balance = await this.tronService.getTrxBalance(account.multiKeys.tron.address);
        account.multiKeys.tron.usdtBalance = await this.tronService.getUsdtBalance(account.multiKeys.tron.address);
      }

      // sync bnb 
      if (account.multiKeys && account.multiKeys.bnb && account.multiKeys.bnb.address) {
        account.multiKeys.bnb.balance = await this.bnbService.getBalance(account.multiKeys.bnb.address);
        const usdtBalance = await this.bnbService.getUSDTBalance(account.multiKeys.bnb.address);
        account.multiKeys.bnb.usdtBalance = Amount.fromPlanck(usdtBalance).getRaw().toString();
      }
    }
  }

  public async activateAccount(account: Account): Promise<void> {
    try {

      if (!account.keys) {
        console.warn('Account does not have keys...ignored');
        return;
      }

      const isMainNet = this.networkService.isMainNet();
      const activatorUrl = isMainNet
        ? environment.activatorServiceUrl.mainNet
        : environment.activatorServiceUrl.testNet;

      const http = HttpClientFactory.createHttpClient(activatorUrl);
      const payload = {
        account: account.account,
        publickey: account.keys.publicKey,
        ref: `fruits-wallet-${environment.version}`
      };
      await http.post('/api/activate', payload);
    } catch (e) {
      if (e instanceof HttpError) {
        const message = e.data && e.data.message;
        throw new Error(message || 'Unknown Error while requesting activation service');
      }
      throw e;
    }
  }

  public async getForgedBlocks(account: Account): Promise<BlockList> {
    return this.api.account.getAccountBlocks({ accountId: account.account });
  }

  public getGTLocked(params: HttpParams): Observable<any> {
    return this.http.get(this.marketUrl + '/fruits/voting/proposal/gt/lock', {
      params: params
    });
  }
}
