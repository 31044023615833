import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Account} from '@fruitsjs/core';
import {TransactionService} from 'app/main/transactions/transaction.service';
import {I18nService} from 'app/layout/components/i18n/i18n.service';
import {StoreService} from '../../../../store/store.service';
import {takeUntil} from 'rxjs/operators';
import {UnsubscribeOnDestroy} from '../../../../util/UnsubscribeOnDestroy';
import {Amount, TRXSymbol, TRXUSDTSymbol, USDTSymbol} from '@fruitsjs/util';
import {AccountService} from '../../../../setup/account/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {constants} from '../../../../constants';
import jsQR from 'jsqr';
import {NotifierService} from 'angular-notifier';
import {IOrderDetail, PaymentGatewayService} from '../../payment-gateway.service';
import {environment} from '../../../../../environments/environment';
import {formatBalance} from '../../../../util/formatAmount';
import {NotificationService} from '../../../notification.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {WarnSendFromPaymentGatewayDialogComponent} from '../../warn-send-from-payment-gateway-dialog/warn-send-from-payment-gateway-dialog.component';
import {Settings} from '../../../../settings';
import {IUnlockMultipleToken} from '../../../../payment-gateway/nft.service';
import {formatBalances} from '../../../../util/util';
import {SignedTx, TokenTransaction, TronService} from '../../../../multi-coin/tron.service';
import {nFormatter} from '../../../../utils';
import {decryptAES, hashSHA256} from '@fruitsjs/crypto';

const isNotEmpty = (value: string) => value && value.length > 0;
let nextId = 0;

@Component({
  selector: 'app-send-trx-usdt-form',
  templateUrl: './send-trx-usdt-form.component.html',
  styleUrls: ['./send-trx-usdt-form.component.scss']
})
export class SendTrxUsdtFormComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  multiCoinUrl = environment.isMainNet ? constants.coinMarketCapURL : constants.multicoinTestURL;

  @ViewChild('amount', {static: true}) public amount: string;
  @ViewChild('file', {static: false}) file: ElementRef;

  stepIndex = 0;
  formatBalance = formatBalances;

  @Input() account: Account;

  public unSubscriber = takeUntil(this.unsubscribeAll);

  public recipientAddress: string;
  fileId = `file-${nextId++}`;

  energyFee = constants.baseEnergyPrice;
  availableBandwidth = constants.maxBandwidth;
  isRecipientActive = false;
  toNotActiveAddressEnergy = environment.toNotActiveAccountEnergy;
  toActiveAddressEnergy = environment.toActiveAccountEnergy;
  energy = '0';
  signedTx: SignedTx;

  public maxFee = '0';
  isSending = false;
  isGettingData = false;
  language: string;

  balances = '0';
  trxBalances = '0';
  symbol = USDTSymbol;
  trxSymbol = TRXSymbol;
  disableInputField = false;
  paymentId: string;

  settings: Settings;
  requestCurrency = constants.requestCurrency;
  currencyListTrxUsdt;
  currencySelectedTrxUsdt;
  currencyListCopy;
  priceTrxUsdt;
  isSelectingTrxUsdt = false;
  priceCurrency;
  changeTrxUsdt;
  maxlength;
  decimals = constants.MAX_LENGTH_USDT;
  node: any;
  tokenName: string;
  fetchData;
  pin = '';

  constructor(private transactionService: TransactionService,
              private i18nService: I18nService,
              private storeService: StoreService,
              private accountService: AccountService,
              private router: Router,
              private route: ActivatedRoute,
              private notifierService: NotifierService,
              private gatewayService: PaymentGatewayService,
              private notificationService: NotificationService,
              private warnDialog: MatDialog,
              private http: HttpClient,
              private tronService: TronService) {
    super();
    this.storeService.settings
      .pipe(
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(async ({language}) => {
        this.language = language;
      });
    this.storeService.getSettings().then((settings: any) => {
      this.settings = settings;
    });
    this.storeService.getSettings().then((result: any) => {
      if (result && result.node) {
        this.node = result.node;
      }
    });
  }

  ngOnInit(): void {
    if (!this.account.multiWallet || !this.account.multiKeys.tron) {
      const dialogRef = this.warnDialog.open(WarnSendFromPaymentGatewayDialogComponent, {
        width: '500px'
      });
      dialogRef.afterClosed().subscribe(() => {
        this.router.navigate(['/dashboard']);
      });
    }

    this.getData().then(() => {
      if (this.route.snapshot.queryParams) {
        setTimeout(async () => {
          this.paymentId = this.route.snapshot.queryParams.paymentId;
          await this.getPaymentOrder(this.paymentId);
        }, 500);
      }

      this.currencyListTrxUsdt = constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined)
        .filter(currency => currency !== undefined);
      this.currencySelectedTrxUsdt = this.settings && this.settings.currency && this.settings.currency.trxusdt
        ? this.currencyListTrxUsdt.filter(item => item.code === this.settings.currency.trxusdt)[0] : this.currencyListTrxUsdt[0];
      this.currencyListCopy = this.currencyListTrxUsdt;
      this.getPriceTrxUsdt();
    });

    this.fetchData = setInterval(() => {
      this.getData();
    }, 10000);
  }

  ngOnDestroy(): void {
    clearInterval(this.fetchData);
  }

  async getPriceTrxUsdt(): Promise<void>{
    if (this.currencySelectedTrxUsdt) {
      const params = new HttpParams()
        .set('source', USDTSymbol)
        .set('destination', this.currencySelectedTrxUsdt.code);
      this.http.get(this.multiCoinUrl + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceTrxUsdt = response.result;
          this.priceCurrency = this.priceTrxUsdt.priceSelectedCurrency;
        }
        this.isSelectingTrxUsdt = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingTrxUsdt = false;
      });
    }
  }

  async valueChange(newValue): Promise<void> {
    try {
      Amount.fromPlanck(newValue);
      if (this.priceCurrency) {
        this.amount = newValue;
        const value = newValue ? (newValue.endsWith('.') ? newValue.replace('.', '') : newValue) : '0';
        this.changeTrxUsdt = this.removeTrailingZeros(Amount.fromPlanck(value).getRaw().multipliedBy(Amount.fromPlanck(this.priceCurrency).getRaw()).toFixed(4));
      }
    } catch (e) {
      this.amount = newValue;
      this.changeTrxUsdt = '0';
    }
  }

  async getData(): Promise<void> {
    if (this.account.multiKeys && this.account.multiKeys.tron) {
      this.account.multiKeys.tron.balance = await this.tronService.getTrxBalance(this.account.multiKeys.tron.address);
      this.account.multiKeys.tron.usdtBalance = await this.tronService.getUsdtBalance(this.account.multiKeys.tron.address);
      this.account = await this.storeService.saveAccount(this.account);
      this.balances = this.account.multiKeys.tron.usdtBalance;
      this.trxBalances = this.account.multiKeys.tron.balance;
    }
  }

  async estimateMaxFee(): Promise<void> {
    this.isGettingData = true;

    // Get energy Fee
    const chainParameters = await this.tronService.getChainParameter();
    this.energyFee = chainParameters.chainParameter.find(i => i.key === 'getEnergyFee').value;

    let availableNetBandwidth = 0;
    let availableNetEnergy = 0;

    // Get available bandwidth
    const accountResource = await this.tronService.getAccountResource(this.account.multiKeys.tron.address);
    if (!!accountResource.freeNetLimit) {
      this.availableBandwidth = accountResource.freeNetLimit - (accountResource.freeNetUsed || 0);
    } else {
      this.availableBandwidth = constants.maxBandwidth;
    }
    if (!!accountResource.NetLimit) {
      availableNetBandwidth = accountResource.NetLimit - (accountResource.NetUsed || 0);
    }
    if (!!accountResource.EnergyLimit) {
      availableNetEnergy = accountResource.EnergyLimit - (accountResource.EnergyUsed || 0);
    }

    this.signedTx = await this.tronService.getTransactionBandwidth(this.account.multiKeys.tron.privateKey,
      this.recipientAddress, Amount.fromPlanck(this.amount).getRaw().multipliedBy(Math.pow(10, constants.MAX_LENGTH_USDT)).toString());

    const isBurnTrxForBandwidth = this.signedTx.bandwidth > Math.max(availableNetBandwidth, this.availableBandwidth);
    const isBurnTrxForEnergy = Amount.fromPlanck(availableNetEnergy).getRaw().isLessThan(this.energy);

    const minFee = isBurnTrxForEnergy
        ? Amount.fromPlanck(this.energy).getRaw()
            .minus(Amount.fromPlanck(availableNetEnergy).getRaw())
            .multipliedBy(Amount.fromPlanck(this.energyFee).getRaw()).toString()
        : '0';
    const maxFeeSun = isBurnTrxForBandwidth
      ? Amount.fromPlanck(minFee).getRaw().plus(Amount.fromPlanck(this.signedTx.bandwidth * constants.SUN_PER_BANDWIDTH).getRaw()).toString()
      : minFee;
    this.maxFee = Amount.fromPlanck(maxFeeSun).getRaw().dividedBy(Math.pow(10, constants.MAX_LENGTH_TRX)).toString();

    this.isGettingData = false;
  }

  checkAmount(): boolean {
    try {
      Amount.fromPlanck(this.amount);
      return true;
    } catch (e) {
      return false;
    }
  }

  public removeTrailingZeros(s: string): string {
    return s.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');
  }

  getTotal(): string {
    try {
      return (this.amount && Number(this.amount) && this.amount !== '0')
        ? Amount.fromPlanck(this.amount).getRaw().toString()
        : '0';
    } catch (e) {
      return '0';
    }
  }

  getTotalTrx(): string {
    return this.maxFee;
  }

  async onSubmit(event): Promise<void> {
    event.stopImmediatePropagation();

    // Check payment gateway
    let order;
    let lockData;
    let unlockData: IUnlockMultipleToken;
    if (this.paymentId) {
      const paymentOrder = await this.gatewayService.isValidNFTOrder(
        this.paymentId,
        this.account.keys.publicKey,
        null,
        this.account.account,
        this.node
      );

      if (!paymentOrder) {
        return;
      }

      if (paymentOrder && !paymentOrder.isDonate) {
        if (paymentOrder && paymentOrder.orderDetail) {
          order = paymentOrder.orderDetail.data;
          this.tokenName = paymentOrder.nftName;
        }

        if (order.multiple) {
          lockData = paymentOrder.lock;
          unlockData = {
            nftName: this.tokenName,
            message: this.account.account,
            signature: lockData.signature,
            lockId: lockData.lockId,
            seller: this.account.account,
            buyer: order.nftName,
            transaction: null
          };
        }
      }
    }

    try {
      this.isSending = true;

      const broadcastTx = await this.tronService.broadcastTx(this.signedTx.signedTx);
      if (broadcastTx.result) {
        this.notifierService.notify('success', this.i18nService.getTranslation('success_send_usdt'));

        if (this.paymentId) {
          this.gatewayService.updateTransactionId(
            this.paymentId,
            broadcastTx.txid,
            this.account.accountRS,
            order && order.multiple ? unlockData.lockId : null
          ).subscribe((res: any) => {
            if (res.code === 0) {
              window.location.href = `${environment.isMainNet ? constants.paymentStatusMainnet : constants.paymentStatusTestnet}/#/buyer/transaction-status/${this.paymentId}`;
            } else {
              this.notifierService.notify('error', this.i18nService.getTranslation('payment_connect_error'));
              this.isSending = false;
            }
          });
        } else {
          setTimeout(async () => {
            await this.router.navigate(['/trx-usdt-transactions']);
          }, 4000);
        }
      } else {
        // unlock NFT
        if (order) {
          await this.unlockToken(unlockData, order.multiple);
        }
        this.isSending = false;

        if (broadcastTx.code && broadcastTx.code === 'TRANSACTION_EXPIRATION_ERROR') {
          await this.estimateMaxFee();
          await this.onSubmit(event);
        } else {
          this.notifierService.notify('error', this.i18nService.getTranslation('error_send_usdt'));
        }
      }
    } catch (e) {
      this.isSending = false;
      this.notifierService.notify('error', this.i18nService.getTranslation('error_send_usdt'));
    }
  }

  async unlockToken(unlockData: any, isErc1155: boolean): Promise<void> {
    if (this.tokenName) {
      try {
        if (isErc1155) {
          await this.gatewayService.unlockMultipleToken(unlockData);
        } else {
          await this.gatewayService.unlockNft(this.tokenName).toPromise();
        }
      } catch (ex) {}
    }
  }

  hasSufficientBalance(): boolean {
    return Amount.fromPlanck(this.getTotal()).getRaw().isLessThanOrEqualTo(Amount.fromPlanck(this.balances).getRaw());
  }

  hasSufficientBalanceTrx(): boolean {
    return Amount.fromPlanck(this.getTotalTrx()).getRaw().isLessThanOrEqualTo(Amount.fromPlanck(this.trxBalances).getRaw());
  }

  canSubmit(): boolean {
    const amount = this.amount && this.amount.endsWith('.') ? this.amount.replace('.', '') : this.amount;
    return isNotEmpty(this.recipientAddress)
      && isNotEmpty(amount)
      && !Amount.fromPlanck(amount).getRaw().eq(0)
      && this.hasSufficientBalance()
      && this.hasSufficientBalanceTrx();
  }

  async validateForm(): Promise<string> {
    if (!this.tronService.isValidAddress(this.recipientAddress)) {
      return this.i18nService.getTranslation('invalid_eth_address');
    }

    if (this.recipientAddress === this.account.multiKeys.tron.address) {
      return this.i18nService.getTranslation('same_account_warn');
    }

    try {
      // check if amount has scientific notation format
      Amount.fromPlanck(this.amount);
    } catch (e) {
      return this.i18nService.getTranslation('invalid_eth_amount');
    }

    const isValidAmount = Number(this.amount);
    if (!isValidAmount && isValidAmount !== 0) {
      return this.i18nService.getTranslation('invalid_eth_amount');
    }
    if (isValidAmount <= 0) {
      return this.i18nService.getTranslation('invalid_eth_amount');
    }

    const privateKey = decryptAES(this.account.keys.signPrivateKey, hashSHA256(this.pin));
    if (!privateKey) {
      return this.i18nService.getTranslation('wrong_pin');
    }

    const triggerConstantContractForTransferring = await this.tronService.estimateEnergyConsumed(this.account.multiKeys.tron.privateKey,
      this.account.multiKeys.tron.address, this.recipientAddress,
      Amount.fromPlanck(this.amount).getRaw().multipliedBy(Math.pow(10, constants.MAX_LENGTH_USDT)).toString()).catch(e => {
        return e;
    });

    if (typeof triggerConstantContractForTransferring === 'string') {
      return triggerConstantContractForTransferring;
    }

    if (!triggerConstantContractForTransferring.energy_used) {
      const response = await this.tronService.getTokenTransaction(this.account.multiKeys.tron.address);
      if (response && response.errorCode === 0) {
        const tokenTransactions: TokenTransaction[] = response.result.token_transfers;
        this.energy = tokenTransactions.length > 0 ? this.toActiveAddressEnergy : this.toNotActiveAddressEnergy;
      } else {
        return response.message;
      }
    } else {
      this.energy = triggerConstantContractForTransferring.energy_used;
    }

    return '';
  }

  resetFile(event): void {
    event.target.value = null;
  }

  parseQR(): void {
    const file = this.file.nativeElement.files[0];
    if (!file) {
      return;
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {

      const width = img.naturalWidth,
        height = img.naturalHeight;

      const reader = new FileReader();
      reader.onload = (): void => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        let qrCodeImageFormat = ctx.getImageData(0, 0, width, height);
        let qr = jsQR(qrCodeImageFormat.data, qrCodeImageFormat.width, qrCodeImageFormat.height);
        if (!qr) {
          ctx.drawImage(img, 10, 10, width, height);
          qrCodeImageFormat = ctx.getImageData(0, 0, width, height);
          qr = jsQR(qrCodeImageFormat.data, qrCodeImageFormat.width, qrCodeImageFormat.height);
        }
        if (qr) {
          const urlText = qr.data;
          if (!urlText.startsWith('trxusdt')) {
            // if (!this.tronService.isValidAddress(urlText)) {
            //   this.notifierService.notify('error', this.i18nService.getTranslation('invalid_eth_address'));
            //   return;
            // } else {
            //   this.recipientAddress = urlText;
            //   this.amount = '0';
            //   this.notifierService.notify('success', this.i18nService.getTranslation('qr_parse_success'));
            // }

            this.notifierService.notify('error', this.i18nService.getTranslation('error_qr_format'));
            return;
          } else {
            this.recipientAddress = urlText.substring(urlText.indexOf(':') + 1, urlText.lastIndexOf('?'));
            const paramsObj = new URLSearchParams(urlText.substring(urlText.lastIndexOf('?') + 1));
            const amount = paramsObj.get('amount');
            if (paramsObj.get('paymentId')) {
              this.disableInputField = true;
              this.paymentId = paramsObj.get('paymentId');
            }
            this.amount = amount;
            this.notifierService.notify('success', this.i18nService.getTranslation('qr_parse_success'));
          }
        } else {
          this.notifierService.notify('error', this.i18nService.getTranslation('qr_parse_fail'));
        }
      };

      window.URL.revokeObjectURL(img.src);
      reader.readAsDataURL(file);
    };
  }


  getPaymentOrder(paymentId: string): void {
    if (!paymentId) {
      return;
    }
    this.gatewayService.getPaymentOrder(paymentId).subscribe(
      (res: any) => {
        if (res && res.code === 0) {

          const data: IOrderDetail = res.data;
          this.amount = formatBalance(data.remaining + '', 'USDT');
          this.recipientAddress = data.address;
          this.disableInputField = true;

        }
      },
      () => {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_unknown'));
      }
    );
  }

  async nextStep(): Promise<void> {
    this.isGettingData = true;
    const validateForm = await this.validateForm().catch(() => { this.isGettingData = false; });

    if (validateForm) {
      this.notifierService.notify('error', validateForm);
      this.isGettingData = false;
      return;
    }

    this.stepIndex += 1;
    this.isGettingData = false;

    await this.estimateMaxFee();
  }

  onCancel(): void {
    this.stepIndex -= 1;
    this.signedTx = undefined;
    this.maxFee = '0';
  }

  getResourceConsumed(): string {
    if (!this.signedTx || !this.energy) {
      return '';
    }
    return this.i18nService.getTranslation('resource_value')
      .replace('__bandwidth__', this.signedTx.bandwidth.toString())
      .replace('__energy__', nFormatter(Number(this.energy), 0));
  }
}
