<div *ngIf="order !== null" style="padding: 15px !important; overflow-x: hidden; height: 100%;" fusePerfectScrollbar
     [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
    <div class="info table" style="position: relative;" *ngIf="isExternalCoin">
        <div style="position: absolute; top: -16px; right: -16px;">
            <button class="close-dialog cursor-pointer" [mat-dialog-close]>X</button>
        </div>
        <div class="row-value-cell">
            <div class="row-title">
                {{ 'status' | i18n }}
            </div>
            <div class="row-value">
                <div>
                    <span *ngIf="order?.swapStatus === 'PENDING'" class="done-status pending">PENDING</span>
                    <span *ngIf="order?.swapStatus === 'COMPLETE'" class="done-status complete">COMPLETED</span>
                    <span *ngIf="order?.swapStatus === 'CANCELLED'" class="done-status cancelled">CANCELED</span>
                    <span *ngIf="order?.swapStatus === 'CANCELING'" class="done-status canceling">CANCELING</span>
                    <span *ngIf="order?.swapStatus === 'PROCESSING'" class="done-status processing">PROCESSING</span>
                </div>
            </div>
        </div>
        <div class="row-value-cell">
            <div class="row-title">
                {{ 'from' | i18n }}
            </div>
            <div class="row-value">
                {{ order.total | number: '1.0-8'}} {{ (tokenName[order.type.source] || order.type.source) }}
            </div>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'to' | i18n }}
            </div>
            <div class="row-value">
                {{ order.amount | number: '1.0-8'}} {{ (tokenName[order.type.destination] || order.type.destination) }}
            </div>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'request_date' | i18n }}
            </div>
            <div class="row-value">
                {{ convertTimestamp(order.timestamp / 1000) }}
            </div>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'request_txn' | i18n }}
            </div>
            <div class="row-value" [mat-dialog-close]>
                <a *ngIf="order.type.source === 'BTC'" [href]="blockstreamUrl + '/tx/' + order.transactionId"
                   target="_blank">{{ order.transactionId }}</a>
                <a *ngIf="order.type.source === 'ETH'" [href]="etherscanUrl + '/tx/' + order.transactionId"
                   target="_blank">{{ order.transactionId }}</a>
                <a *ngIf="order.type.source === 'FRTS'"
                   [routerLink]="['/frts-transactions/transaction', order.transactionId]">{{ order.transactionId }}</a>
                <a *ngIf="order.type.source === 'USDT_TRC'" [href]="tronscanUrl + '/#/transaction/' +  order.transactionId"
                target="_blank">{{ order.transactionId }}</a>
                <a *ngIf="order.type.source === 'USDT_ERC'" [href]="etherscanUrl + '/tx/' + order.transactionId"
                target="_blank">{{ order.transactionId }}</a>
                <a *ngIf="order.type.source === 'USDT_BEP'" [href]="bscScanUrl + '/tx/' + order.transactionId"
                target="_blank">{{ order.transactionId }}</a>
            </div>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'rate' | i18n }}
            </div>
            <div class="row-value">
        <!-- <span *ngIf="order.type.source === 'FRTS'">{{1 / order.price | number: '1.0-8'}} {{ (currencyMapping[order.type.source]
            || order.type.source) }}
            ~ 1 {{ (currencyMapping[order.type.destination] || order.type.destination) }}</span> -->
        <span>1 {{ (currencyMapping[order.type.source] || order.type.source) }}
            ~ {{order.price | number: '1.0-18'}} {{ (currencyMapping[order.type.destination] || order.type.destination) }}</span>
            </div>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'network_fee' | i18n }}
            </div>
            <div *ngIf="order.networkFee" class="row-value">
                {{ toPlainString(order.networkFee) }} {{ (networkFeeMapping[order.type.source] || order.type.source) }}
            </div>
            <div *ngIf="!order.networkFee" class="row-value">
                {{ 0 }} {{ (networkFeeMapping[order.type.source] || order.type.source) }}
            </div>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'swap_fee' | i18n }}
            </div>
            <div class="row-value">
                {{ order.swapFee | number: '1.0-8'}} {{ (currencyMapping[order.type.source] || order.type.source) }}
            </div>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'total_amount' | i18n }}
            </div>
            <div class="row-value">
                {{ getTotalAmount(order) }}
            </div>
        </div>

        <div style="text-align: right">
            <button class="{{ !isLoading  ? 'inter btn-common' : 'inter btn-common-disable' }} button"
                    *ngIf="order.swapStatus === 'PROCESSING'"
                    [disabled]="isLoading"
                    (click)="cancelOrder(order)">{{ 'cancel' | i18n }}</button>
        </div>

        <div class="completed-container" *ngIf="order.swapStatus === 'CANCELLED' && order.completedTimestamp">
            <div class="column-title-cell">
                <div class="column-title">
                    {{ 'cancel_date' | i18n }}
                </div>
            </div>
            <div class="column-value-cell column-completed-value-cell">
                <div class="column-value">
                    {{ convertTimestamp(order.completedTimestamp / 1000) }}
                </div>
            </div>
        </div>

        <div *ngIf="order && order.completedDate && order.swapStatus === 'COMPLETE'" style="padding-top: 10px;">
            <mat-divider></mat-divider>
            <div class="completed-container" style="padding-top: 10px;">
                <div class="column-title-cell">
                    <div class="column-title">
                        {{ 'matching_amount' | i18n }}
                    </div>
                    <div class="column-title">
                        {{ 'complete_date' | i18n }}
                    </div>
                </div>
                <div class="column-value-cell column-completed-value-cell">
                    <div>
                        <div class="column-value">
                            {{ order.amount | number: '1.0-8'}} {{ currencyMapping[order.type.destination] || order.type.destination }}
                        </div>
                        <div class="column-value">
                            {{ convertTimestamp(order.completedTimestamp / 1000) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info table" style="position: relative;" *ngIf="!isExternalCoin && order">
        <div style="position: absolute; top: -16px; right: -16px;">
            <button class="close-dialog cursor-pointer" [mat-dialog-close]>X</button>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'status' | i18n }}
            </div>
            <div class="row-value-status">
                <div>
                    <span *ngIf="order?.status === 'PENDING'" class="done-status pending">PENDING</span>
                    <span *ngIf="order?.status === 'COMPLETE'" class="done-status complete">COMPLETED</span>
                    <span *ngIf="order?.status === 'CANCELLED'" class="done-status cancelled">CANCELED</span>
                    <span *ngIf="order?.status === 'CANCELING'" class="done-status canceling">CANCELING</span>
                    <span *ngIf="order?.status === 'PROCESSING'"
                          class="done-status processing">PROCESSING</span>
                </div>
            </div>
        </div>
        <div class="row-value-cell">
            <div class="row-title">
                {{ 'from' | i18n }}
            </div>
            <div class="row-value">
                <div *ngIf="order.type === 'ASK'">
                    <div>{{ order.tokenQuantity  | number: '1.0-8'}} {{order.assetName}}</div>
                </div>
                <div *ngIf="order.type === 'BID'">
                    <div>{{ order.frtsQuantity | number: '1.0-8' }} FRTS</div>
                </div>
            </div>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'to' | i18n }}
            </div>
            <div class="row-value">
                <div *ngIf="order.type === 'ASK'">
                    <div>{{ order.frtsQuantity | number: '1.0-8'}} FRTS</div>
                </div>
                <div *ngIf="order.type === 'BID'">
                    <div>{{ order.tokenQuantity | number: '1.0-8' }} {{ order.assetName }}</div>
                </div>
            </div>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'request_date' | i18n }}
            </div>
            <div class="row-value">
                {{ convertTimestamp(order.createdDate / 1000) }}

            </div>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'request_txn' | i18n }}
            </div>
            <div class="row-value">
                <div [mat-dialog-close]>
                    <a [routerLink]="['/frts-transactions/transaction', order.transaction]">{{ order.transaction }}</a>
                </div>
            </div>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'rate' | i18n }}
            </div>
            <div class="row-value">
          <span
              *ngIf="order.type === 'ASK'"> 1  {{ order.assetName + ' = '  }} {{   order.ratePerUnit | number: '1.0-8'}}
              FRTS</span>
                <span *ngIf="order.type === 'BID'"> 1 FRTS = {{ 1/order.ratePerUnit | number: '1.0-8'}} {{ order.assetName}}</span>
            </div>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'network_fee' | i18n }}
            </div>
            <div class="row-value">
                {{ 0 }} FRTS
            </div>

        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'swap_fee' | i18n }}
            </div>
            <div class="row-value">
                {{ order.swapFee | number: '1.0-8'}} FRTS
            </div>
        </div>

        <div class="row-value-cell">
            <div class="row-title">
                {{ 'total_amount' | i18n }}
            </div>
            <div class="row-value">
                <div>
                    <div *ngIf="order.type === 'ASK'">
                        {{order.tokenQuantity | number: '1.0-8'}} {{ order.assetName}}
                        + {{ order.swapFee | number: '1.0-8'}} FRTS
                    </div>

                    <div *ngIf="order.type === 'BID'">
                        {{ calculateTotalAmount(order.frtsQuantity, order.swapFee) | number: '1.0-8'}} FRTS
                    </div>

                </div>
            </div>
        </div>

        <div *ngFor="let item of orderDetail" style="padding-top: 10px;">
            <mat-divider></mat-divider>
            <div class="completed-container" style="padding-top: 10px;">
                <div class="column-title-cell">
                    <div *ngIf="item.status !== 'COMPLETE'">
                        <div class="column-title">{{ 'remain_amount' | i18n }}</div>
                        <div class="column-title">{{ 'need_to_swap_to' | i18n }}
                        </div>
                    </div>
                    <div *ngIf="item.status === 'COMPLETE'">
                        <div class="column-title">{{ 'matching_from' | i18n }}</div>
                        <div class="column-title">{{ 'matching_to' | i18n }}</div>
                        <div class="column-title">{{ 'complete_date' | i18n }}</div>
                        <div class="column-title">{{ 'complete_txn' | i18n }}</div>
                    </div>

                    <div  *ngIf="item.status === 'CANCELLED'" >
                        <div class="column-title">{{ 'cancel_date' | i18n }}</div>
                        <div class="column-title">{{ 'cancel_txn' | i18n }}</div>
                    </div>

                </div>
                <div class="column-value-cell column-completed-value-cell">
                    <div>
                        <div *ngIf="item.status !== 'COMPLETE'">
                            <div *ngIf="order.type === 'ASK'" class="column-value">
                                {{ item?.quantityQNT | number: '1.0-8' }} {{order.assetName}}
                            </div>
                            <div *ngIf="order.type === 'BID'" class="column-value">
                                {{ (item?.quantityQNT * item?.priceNQT) | number: '1.0-8'}} FRTS
                            </div>
                        </div>
                        <div *ngIf="item.status === 'COMPLETE'">
                            <div *ngIf="order.type === 'ASK'" class="column-value">
                                {{ item?.quantityQNT | number: '1.0-8' }} {{order.assetName}}
                            </div>
                            <div *ngIf="order.type === 'ASK'"
                                 class="column-value">{{ (item?.quantityQNT * item?.priceNQT) | number: '1.0-8'}} FRTS
                            </div>
                            <div *ngIf="order.type === 'BID'" class="column-value">
                                {{ (item?.quantityQNT * item?.priceNQT) | number: '1.0-8'}} FRTS
                            </div>
                            <div *ngIf="order.type === 'BID'"
                                 class="column-value">{{ item?.quantityQNT | number: '1.0-8' }} {{order.assetName}}
                            </div>
                        </div>
                        <div *ngIf="item.status !== 'COMPLETE'">
                            <div *ngIf="order.type === 'BID'" class="column-value">
                                {{ item?.quantityQNT | number: '1.0-8' }} {{order.assetName}}
                            </div>
                            <div *ngIf="order.type === 'ASK'" class="column-value">
                                {{ (item?.quantityQNT * item?.priceNQT) | number: '1.0-8'}} FRTS
                            </div>

                        </div>
                        <div *ngIf="item.status === 'COMPLETE'" class="column-value">
                            {{getDate(item?.completeDate)}}
                        </div>
                        <div *ngIf="item.status === 'CANCELLED'" class="column-value">
                            {{ convertTimestamp(item?.completeDate / 1000) }}
                        </div>
                        <div *ngIf="item.status === 'COMPLETE' || item.status === 'CANCELLED'" class="column-value">
                            <div [mat-dialog-close]>
                                <a [routerLink]="['/frts-transactions/transaction', item.order]">{{ item.order }}</a>
                            </div>
                        </div>
                    </div>
                    <div style="display: flex; align-items: center; justify-content: center;">
                        <button class="{{ !isLoading  ? 'inter btn-common' : 'inter btn-common-disable' }} button"
                                *ngIf="item.status === 'PROCESSING'" class="btn-common"
                                [disabled]="isLoading"
                                (click)="openCancelDialog(order, item)">{{ 'cancel' | i18n }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
