import {Component, ContentChild, OnInit, QueryList, TemplateRef, ViewChildren} from '@angular/core';
import {StoreService} from '../../store/store.service';
import {AccountService} from '../../setup/account/account.service';
import {environment} from '../../../environments/environment';
import {constants} from '../../constants';
import {ActivatedRoute, Router} from '@angular/router';
import {Account} from '@fruitsjs/core';
import {NftService} from '../nft.service';
import {NotifierService} from 'angular-notifier';
import {ItemComponent} from './item/item.component';
import {MatDialog} from '@angular/material/dialog';
import {ManageWalletDialogComponent} from './manage-wallet-dialog/manage-wallet-dialog.component';
import {I18nService} from '../../layout/components/i18n/i18n.service';

@Component({
    selector: 'app-select-account',
    templateUrl: './select-account.component.html',
    styleUrls: ['./select-account.component.scss']
})
export class SelectAccountComponent implements OnInit {

    @ViewChildren('itemAccount') itemComponents: QueryList<ItemComponent>;

    @ContentChild(TemplateRef, {static: false}) selectAccount: TemplateRef<any>;

    accounts: Account[];
    multiCoinAccounts: Account[];
    fruitsOnlyAccounts: Account[];
    collectionUrl = environment.isMainNet ? constants.NFT_COLLECTION_MAINNET : constants.NFT_COLLECTION_TESTNET;
    status: string;
    account: Account;
    callBackUrl: string;
    message: string;
    hasMessage = false;
    currentUrl;

    constructor(private _storeService: StoreService, private accountService: AccountService,
                private marketService: NftService,
                private router: Router,
                private dialog: MatDialog,
                private i18nService: I18nService,
                private route: ActivatedRoute,
                private notifyService: NotifierService) {
        this.route.queryParams.subscribe(params => {
            this.currentUrl = params['currentUrl'] || '';
        });
    }

    async ngOnInit(): Promise<void> {
        const accountList = await this._storeService.getAllAccounts();
        this.accounts = accountList.filter(item => !!item.keys);
        this.multiCoinAccounts = this.accounts.filter(item => item.multiWallet);
        this.fruitsOnlyAccounts = this.accounts.filter(item => !item.multiWallet);
        console.log(this.multiCoinAccounts);
        console.log(this.fruitsOnlyAccounts);

        this.message = sessionStorage.getItem('message');
        this.hasMessage = !!this.message;
    }

    getMultiWalletIndex(address: string, accounts: Account[]): string {
        if (accounts && accounts.length) {
            let index = 0;
            for (let i = 0; i < accounts.length; i++) {
                if (accounts[i].account === address) {
                  break;
                }
                index++;
            }
            return index === 0 ? '' : index.toString();
        }
        return '0';
    }

    async onClickAccount(account: any): Promise<void> {
        await this.accountService.selectAccount(account);
        this.account = account;

        this.status = sessionStorage.getItem('status');
        if (this.status) {
            this.callBackUrl = decodeURIComponent(sessionStorage.getItem('callBack'));
            sessionStorage.removeItem('status');
            sessionStorage.removeItem('callBack');
            sessionStorage.removeItem('message');
            this.checkStatus(account);
        } else {
            await this.router.navigate(['/']);
        }
    }

    checkStatus(account: Account): void {
        switch (this.status) {
            case 'sell':
            case 'cancel':
            case 'transfer':
            case 'transfer-detail':
            case 'create':
            case 'edit':
                window.open(`${this.collectionUrl}/nft/my-nfts?account=${account.accountRS}`, '_self');
                break;
            case 'connect':
                window.open(this.callBackUrl + `?account=${this.account.accountRS}&publicKey=${this.account.keys.publicKey}`, '_self');
                break;
            case 'home':
                this.onConnectAccount(account);
                break;
            default:
                this.router.navigate(['/']);
                break;
        }
    }

    async onConnectAccount(account: any): Promise<void> {
        try {
            const createProfile = await this.marketService.createProfile(this.account.accountRS).toPromise();
            if (createProfile && createProfile.errorCode === 0) {
                window.open(`${this.collectionUrl}/save/account/${account.accountRS}?currentUrl=${encodeURIComponent(this.currentUrl)}`, '_self');
            }
        } catch (e) {
            this.notifyService.notify('error', 'Create NFT profile Failed, please try again later.');
            // After 2 seconds redirect back to NFT
            setTimeout(() => {
                window.open(`${this.collectionUrl}`, '_self');
            }, 2000);
        }
    }

    hideAllAccountButtons(account: string): void {
        this.itemComponents.filter(child => child.show && child.account.account !== account).forEach((child) => {
            child.show = false;
        });
    }

    onOpenManageWalletDialog(): void {
      this.dialog.open(ManageWalletDialogComponent, {
        width: '400px',
        panelClass: 'manage-wallet-dialog',
        data: {
          status: sessionStorage.getItem('status')
        }
      });
    }

    getWalletName(account: Account, accounts: Account[]): string {
        if (!account) {
            return '';
        }
        return account.name ? account.name
            : 'Wallet name ' + this.getMultiWalletIndex(account.account, accounts);
    }
}
