<div class="page-layout blank simple" fusePerfectScrollbar>

  <!-- HEADER -->
  <div class="header accent p-40 h-140" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
       fxLayoutAlign.gt-xs="space-between center">

    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
      <div fxLayout="row" fxLayoutAlign="start center">
        <a [routerLink]="['/dashboard']">
          <!--          <mat-icon class="secondary-text s-18">home</mat-icon>-->
          <img src="./assets/icons/menu/icon-home.png" height="16" width="16" alt=""/>
        </a>
        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
        <span class="secondary-text">{{ 'swap_history_eth' | i18n }}</span>
      </div>
      <div class="current-screen h1 mt-16">{{ 'swap_history_eth' | i18n }}</div>
    </div>
  </div>
  <!-- / HEADER -->

  <div class="p-40 content">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="assets-table sp-font">
      <mat-table [dataSource]="dataSourceSellOrders" matSort>

        <ng-container matColumnDef="transaction_id">
          <mat-header-cell *matHeaderCellDef>{{ 'transaction_id' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset">
            <a *ngIf="asset.type.source === 'ETH'" [href]="etherscanUrl + '/tx/' + asset.transactionId"
               target="_blank" matTooltip="{{ asset.transactionId }}"><div  class="hide-long-text">{{ asset.transactionId }}</div></a>
            <a *ngIf="asset.type.source === 'FRTS'"
               [routerLink]="['/frts-transactions/transaction', asset.transactionId]" matTooltip="{{ asset.transactionId }}" class="word-break">{{ asset.transactionId }}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="request_date">
          <mat-header-cell *matHeaderCellDef>{{ 'request_date' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset" >
            <span>{{ convertTimestamp(asset.timestamp / 1000) }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="swap_content">
          <mat-header-cell *matHeaderCellDef style="max-width: 200px;">{{ 'swap_content' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset" style="max-width: 200px;">
            <div>
              <div>{{ 'from' | i18n }}: {{ asset.total | number: '1.0-8'}} {{ asset.type.source }} </div>
              <div>{{ 'to' | i18n }}: {{ asset.amount | number: '1.0-8'}} {{ asset.type.destination }}</div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="fee">
          <mat-header-cell *matHeaderCellDef>{{ 'fee' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset">
            <div>
              <div>
                {{ 'network_fee' | i18n }}: {{!asset.networkFee ? 0 : asset.networkFee | number: '1.0-8'}} {{ asset.type.source }}
              </div>
              <div>
                {{ 'swap_fee' | i18n }}: {{ asset.swapFee | number: '1.0-8'}} {{ asset.type.source }}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="total_amount">
          <mat-header-cell *matHeaderCellDef>{{ 'total_amount' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset">
            <span>{{ getTotalAmount(asset) | number: '1.0-18'}} {{ asset.type.source }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="rate">
          <mat-header-cell *matHeaderCellDef>{{ 'rate' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset">
            <span>1 {{ asset.type.source }} ~ {{ asset.price | number: '1.0-18' }} {{ asset.type.destination }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef style="max-width: 150px;">{{ 'status' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset" style="max-width: 150px;">
            <span>{{ convertStatus(asset.swapStatus) }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef style="max-width: 150px;">{{ 'action' | i18n }}</mat-header-cell>
          <mat-cell *matCellDef="let asset" style="max-width: 150px;">
            <button class="btn-common" (click)="openDetailDialog(asset)">{{ 'details' | i18n }}</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row class="{{ row.isPending ? 'pending-order' : (row.isCanceling ? 'canceling-order' : '') }}"
                 *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <div [hidden]="!totalElements || totalElements <= 10">
        <mat-paginator
          #paginator
          [length]="totalElements"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          [pageSizeOptions]="[10, 20, 50]"
          [showFirstLastButtons]="true"
          (page)="pageChange($event)"
        >
        </mat-paginator>
      </div>

    </div>
  </div>
</div>
