import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AccountResolver} from 'app/setup/account/account.resolver';
import {SendBurstComponent} from './send-burst.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FormsModule} from '@angular/forms';
import {SetupModule} from 'app/setup/setup.module';
import {NotifierModule} from 'angular-notifier';
import {I18nModule} from 'app/layout/components/i18n/i18n.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {SuggestFeeResolver} from '../../network/suggest-fee.resolver';
import {NetworkModule} from 'app/network/network.module';
import {LayoutModule} from 'app/layout/layout.module';
import {SendBurstFormComponent} from './send-burst-form/send-burst-form.component';
import {SendMultiOutFormComponent} from './send-multi-out-form/send-multi-out-form.component';
import {WarnSendDialogComponent} from './warn-send-dialog/warn-send-dialog.component';
import {PageModule} from '../../components/page/page.module';
import {LoginGuard} from 'app/login/login-guard.service';
import {BatchRecipientsDialogComponent} from './batch-recipients-dialog/batch-recipients-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import {DomainService} from './domain/domain.service';
import {AppSharedModule} from '../../shared/shared.module';
import {SendBtcComponent} from './send-btc';
import {SendBtcFormComponent} from './send-btc/send-btc-form/send-btc-form.component';
import {SendEthComponent} from './send-eth';
import {SendEthFormComponent} from './send-eth/send-eth-form/send-eth-form.component';
import {MatRadioModule} from '@angular/material/radio';
import {WarnSendFromPaymentGatewayDialogComponent} from './warn-send-from-payment-gateway-dialog/warn-send-from-payment-gateway-dialog.component';
import {SendUsdtComponent} from './send-usdt';
import {SendUsdtFormComponent} from './send-usdt/send-usdt-form/send-usdt-form.component';
import { SendTrxComponent } from './send-trx';
import { SendTrxFormComponent } from './send-trx/send-trx-form/send-trx-form.component';
import {MatStepperModule} from '@angular/material/stepper';
import { SendTrxUsdtComponent } from './send-trx-usdt';
import { SendTrxUsdtFormComponent } from './send-trx-usdt/send-trx-usdt-form/send-trx-usdt-form.component';
import { SendBnbComponent } from './send-bnb';
import { SendBnbUsdtComponent } from './send-bnb-usdt';
import { SendBnbFormComponent } from './send-bnb/send-bnb-form/send-bnb-form.component';
import { SendBnbUsdtFormComponent } from './send-bnb-usdt/send-bnb-usdt-form/send-bnb-usdt-form.component';

const routes = [
  {
    path: 'send',
    component: SendBurstComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver,
      suggestedFees: SuggestFeeResolver
    }
  },
  {
    path: 'send/btc',
    component: SendBtcComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'send/eth',
    component: SendEthComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'send/usdt',
    component: SendUsdtComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'send/trx',
    component: SendTrxComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'send/trxusdt',
    component: SendTrxUsdtComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'send/payment',
    component: SendBurstComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver,
      suggestedFees: SuggestFeeResolver
    }
  },
  {
    path: 'send/btc/payment',
    component: SendBtcComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'send/eth/payment',
    component: SendEthComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'send/usdt/payment',
    component: SendUsdtComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'send/trx/payment',
    component: SendTrxComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'send/trxusdt/payment',
    component: SendTrxUsdtComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'send/bnb',
    component: SendBnbComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'send/bnb-usdt',
    component: SendBnbUsdtComponent,
    canActivate: [LoginGuard],
    resolve: {
      account: AccountResolver
    }
  },
  {
    path: 'requestBurst',
    redirectTo: 'send',
    pathMatch: 'full'
  }
];

@NgModule({
  providers: [
    DomainService
  ],
  declarations: [
    SendBurstComponent,
    SendBurstFormComponent,
    SendMultiOutFormComponent,
    WarnSendDialogComponent,
    BatchRecipientsDialogComponent,
    SendBtcComponent,
    SendBtcFormComponent,
    SendEthComponent,
    SendEthFormComponent,
    SendUsdtComponent,
    SendUsdtFormComponent,
    WarnSendFromPaymentGatewayDialogComponent,
    SendTrxComponent,
    SendTrxFormComponent,
    SendTrxUsdtComponent,
    SendTrxUsdtFormComponent,
    SendBnbComponent,
    SendBnbFormComponent,
    SendBnbUsdtComponent,
    SendBnbUsdtFormComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    FuseSharedModule,
    SetupModule,
    NotifierModule,
    I18nModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatGridListModule,
    MatCheckboxModule,
    NetworkModule,
    LayoutModule,
    MatAutocompleteModule,
    RouterModule.forChild(routes),
    MatChipsModule,
    MatBadgeModule,
    MatTooltipModule,
    MatDialogModule,
    PageModule,
    MatSelectModule,
    AppSharedModule,
    MatRadioModule,
    MatStepperModule
  ],
  entryComponents: [
    WarnSendDialogComponent,
    BatchRecipientsDialogComponent,
    WarnSendFromPaymentGatewayDialogComponent
  ],
  exports: []
})
export class SendBurstModule {
}
