import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {StoreService} from '../../store/store.service';
import {AccountService} from '../../setup/account/account.service';
import {FuseConfigService} from '../../../@fuse/services/config.service';
import {filter, switchMap} from 'rxjs/operators';
import {Account} from '@fruitsjs/core';
import {Settings} from '../../settings';
import {environment} from '../../../environments/environment';
import {constants} from '../../constants';
import {HttpParams} from '@angular/common/http';
import {NftService} from '../nft.service';
import {NotifierService} from 'angular-notifier';
import {I18nService} from '../../layout/components/i18n/i18n.service';

const errorHandler = console.error;

@Injectable({
  providedIn: 'root'
})
export class SelectAccountGuard implements CanActivate {

  collectionUrl = environment.isMainNet ? constants.NFT_COLLECTION_MAINNET : constants.NFT_COLLECTION_TESTNET;
  params: any;

  constructor(private _storeService: StoreService,
              private _accountService: AccountService,
              private _fuseConfigService: FuseConfigService,
              private _router: Router,
              private nftService: NftService,
              private notifierService: NotifierService,
              private i18nService: I18nService) {
    this.removeSession();
    const status = this.searchParam('status', window.location.href);
    if (status) {
      sessionStorage.setItem('status', status);
    }
    const callBack = this.searchParam('callBack', window.location.href);
    if (callBack) {
      sessionStorage.setItem('callBack', callBack);
    }
    const message = this.searchParam('message', window.location.href);
    if (message) {
      sessionStorage.setItem('message', message);
    }
  }

  removeSession(): void {
    sessionStorage.removeItem('status');
    sessionStorage.removeItem('callBack');
    sessionStorage.removeItem('message');
  }

  searchParam(name, url): string {
    if (!url) {
      url = location.href;
    }
    name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
    const regexS = '[\\?&]' + name + '=([^&#]*)';
    const regex = new RegExp(regexS);
    const results = regex.exec(url);
    return results == null ? null : results[1];
  }

  hideMenus(): void {
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  canActivate(): Observable<boolean> {
    return this._storeService.ready.pipe(
      filter(Boolean),
      switchMap(async () => {
        const orderId = this.searchParam('orderId', window.location.href);
        if (orderId) {
          const rqParams = new HttpParams().set('orderId', orderId);
          const response = await this.nftService.getNftOrder(rqParams).toPromise();
          if (response && response.errorCode === 1) {
            this.notifierService.notify('error', this.i18nService.getTranslation(response.message));
            await this._router.navigate(['/']);
          }

          if (response && response.errorCode === 0) {
            this.params = JSON.parse(response.result.params);
            const allAccounts: Account[] | any = await this._storeService.getAllAccounts().catch(errorHandler);
            const accountsFilter = allAccounts.filter(a => a.keys);
            if (accountsFilter && accountsFilter.length > 0) {
              const selectedAccount: Account = await this._storeService.getSelectedAccount();
              const account = this.params.account;
              const status = this.params.status;
              const callbackUrl = this.params.callbackUrl;

              if (account && status) {
                switch (status) {
                  case 'sell':
                  case 'cancel':
                  case 'transfer':
                  case 'transfer-detail':
                  case 'create':
                  case 'edit':
                  case 'UPDATE_PROFILE':
                  case 'transfer-multi-token':
                  case 'increase-quantity':
                  case 'confirm-transaction':
                    if (!accountsFilter.map(a => a.accountRS).includes(account)) {
                      await this._router.navigate(['/logout'], {queryParams: {orderId: orderId}});
                    } else if (accountsFilter.map(a => a.accountRS).includes(account) && selectedAccount.accountRS !== account) {
                      await this._router.navigate(['/change-account'], {queryParams: {orderId: orderId}});
                    }
                    this.hideMenus();
                    return true;
                }
              }

              // use for third party
              if (account && callbackUrl) {
                 if (!accountsFilter.map(a => a.accountRS).includes(account)) {
                      await this._router.navigate(['/logout'], {queryParams: {orderId: orderId}});
                    } else if (accountsFilter.map(a => a.accountRS).includes(account) && selectedAccount.accountRS !== account) {
                      await this._router.navigate(['/change-account'], {queryParams: {orderId: orderId}});
                    }
                    this.hideMenus();
                    return true;
              }

              this.hideMenus();
              return true;
            } else {
              const account = this.params.account;
              const status = this.params.status;
              if (account && status) {
                switch (status) {
                  case 'sell':
                  case 'cancel':
                  case 'transfer':
                  case 'transfer-detail':
                  case 'create':
                  case 'edit':
                  case 'UPDATE_PROFILE':
                    await this._router.navigate(['/logout'], {queryParams: {orderId: orderId}});
                    this.hideMenus();
                    return true;
                }
              }
              const settings: Settings | any = await this._storeService.getSettings().catch(errorHandler);
              settings.agree = true;
              await this._storeService.saveSettings(settings);
              await this._router.navigate(['/wallet']);
              return false;
            }
          }
        } else {
          const allAccounts: Account[] | any = await this._storeService.getAllAccounts().catch(errorHandler);
          const accountsFilter = allAccounts.filter(a => a.keys);
          if (accountsFilter && accountsFilter.length > 0) {
            const status = this.searchParam('status', window.location.href);
            if (status && status === 'connect') {
              this.hideMenus();
              return true;
            }
            if (status && status === 'home') {
              this.hideMenus();
              return true;
            }
            await this._router.navigate(['/']);
            return false;
          } else {
            const settings: Settings | any = await this._storeService.getSettings().catch(errorHandler);
            settings.agree = true;
            await this._storeService.saveSettings(settings);
            const currentUrl = this.searchParam("currentUrl", window.location.href);
            await this._router.navigate(['/wallet'], {queryParams: {orderId: orderId, currentUrl: currentUrl}});
            return false;
          }
        }
      }));
  }

}
