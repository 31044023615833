<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

  <div class="logo">
    <img src="assets/images/logos/logo_unfolded.svg" class="logo-unfolded" alt="">
    <img (click)="toggleSidebarFolded()" src="assets/images/logos/logo_folded.svg" class="logo-folded" alt="">
    <small id="version" class="logo-unfolded">{{getVersion()}}</small>
  </div>

  <div class="buttons">

    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
      <mat-icon class="secondary-text">menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
      <mat-icon class="secondary-text">arrow_back</mat-icon>
    </button>

  </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" fusePerfectScrollbar
     [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

  <div *ngIf="selectedAccount" class="user" fxLayout="column"
       [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div *ngIf="!selectedAccount.multiWallet" class="h3 username">{{ selectedAccount.accountRS }}</div>
<!--    <div class="h5 username">{{ getBalance() | burstAmount:'planck':false }}</div>-->
<!--    <div class="h5 alias hint-text mt-4" *ngIf="selectedAccount.type !== 'offline'" [matMenuTriggerFor]="menu">-->
<!--      <span class="account_info">{{ getAccountName() }}-->
<!--        <mat-icon>keyboard_arrow_down</mat-icon></span>-->
<!--    </div>-->
<!--    <div class="h5 alias hint-text mt-4" *ngIf="selectedAccount.type === 'offline'">-->
<!--      <span>{{'hint_offline_account' | i18n}}</span>-->
<!--    </div>-->
    <a
      class="avatar-container"
      [routerLink]="['set-account-info']"
    >
<!--      <canvas #avatar></canvas>-->
<!--      <div id="fruits"></div>-->
      <img id="icon-menu-custom" width="{{ selectedAccount.multiWallet ? '40' : '20' }}" height="{{ selectedAccount.multiWallet ? '46' : '23' }}" src="assets/icons/avatar.svg"  alt="">
    </a>
  </div>

  <mat-menu #frtsMenu="matMenu">
    <button *ngIf="isAdmin" mat-menu-item (click)="copy(selectedAccount.account)">
      <span>{{ 'copy_account_id' | i18n }}</span>
    </button>
    <button mat-menu-item (click)="copy(selectedAccount.accountRS)">
      <span>{{ 'copy_account_address' | i18n }}</span>
    </button>
    <button *ngIf="selectedAccount.accountRSExtended" mat-menu-item (click)="copy(selectedAccount.accountRSExtended)">
      <span>{{ 'copy_account_address_extended' | i18n }}</span>
    </button>
    <button *ngIf="selectedAccount.keys && selectedAccount.keys.publicKey && isAdmin" mat-menu-item (click)="copy(selectedAccount.keys.publicKey)">
      <span>{{ 'copy_account_publickey' | i18n }}</span>
    </button>
  </mat-menu>

  <mat-menu #ethMenu="matMenu">
    <button mat-menu-item (click)="copy(selectedAccount.multiKeys.eth.address)">
      <span>{{ 'copy_eth_address' | i18n }}</span>
    </button>
  </mat-menu>

  <mat-menu #btcMenu="matMenu">
    <button mat-menu-item (click)="copy(selectedAccount.multiKeys.btc.address)">
      <span>{{ 'copy_btc_address' | i18n }}</span>
    </button>
    <button *ngIf="isAdmin" mat-menu-item (click)="copy(selectedAccount.multiKeys.btc.publicKey)">
      <span>{{ 'copy_btc_public_key' | i18n }}</span>
    </button>
  </mat-menu>
  <mat-menu #tronMenu="matMenu">
    <ng-template matMenuContent let-token="token">
      <div [ngSwitch]="token">
        <button mat-menu-item *ngSwitchCase="'trxusdt'" (click)="copy(selectedAccount.multiKeys.tron.address)">
          <span>{{ 'copy_tron_address' | i18n }}</span>
        </button>
        <button mat-menu-item *ngSwitchCase="'trx'" (click)="copy(selectedAccount.multiKeys.tron.address)">
          <span>{{ 'copy_tron_address_1' | i18n }}</span>
        </button>
      </div>
    </ng-template>
  </mat-menu>

  <mat-menu #bscMenu="matMenu">
    <ng-template matMenuContent let-token="token">
      <div [ngSwitch]="token">
        <button mat-menu-item *ngSwitchCase="'bep'" (click)="copy(selectedAccount.multiKeys.bnb.address)">
          <span>{{ 'copy_bep_address' | i18n }}</span>
        </button>
        <button mat-menu-item *ngSwitchCase="'bnb'" (click)="copy(selectedAccount.multiKeys.bnb.address)">
          <span>{{ 'copy_bnb_address' | i18n }}</span>
        </button>
      </div>
    </ng-template>
  </mat-menu>

  <div class="multi-coin-content">
    <div class="border-line"></div>
    <div class="multi-coin-container" style="display: flex; justify-content: space-between">
      <div class="multi-coin-left" style="display: flex;">
        <div class="multi-coin-icon">
          <img src="assets/icons/multi-coin/frts-icon.svg" height="40" width="40" alt=""/>
        </div>
        <div class="multi-coin-info">
          <div class="multi-coin-name">FRUITS</div>
          <div class="multi-coin-balance" matTooltip="{{ formatBalance(getBalance()) }} FRTS">
            <div class="hide-long-text">{{ formatBalance(getBalance()) }}</div> FRTS
          </div>
        </div>
      </div>
      <div class="multi-coin-right">
        <img [matMenuTriggerFor]="frtsMenu" src="assets/icons/menu/dotted-menu.png" height="15" width="15" alt=""/>
      </div>
    </div>

    <div *ngIf="selectedAccount.multiWallet && selectedAccount.multiKeys.btc" class="multi-coin-container" style="display: flex; justify-content: space-between">
      <div class="multi-coin-left" style="display: flex;">
        <div class="multi-coin-icon">
          <img src="assets/icons/multi-coin/btc-icon.svg" height="40" width="40" alt=""/>
        </div>
        <div class="multi-coin-info">
          <div class="multi-coin-name">Bitcoin</div>
          <div class="multi-coin-balance" matTooltip="{{ formatBalance(getBtcBalance().toString()) }} BTC">
            <div class="hide-long-text">{{ formatBalance(getBtcBalance().toString()) }}</div> BTC
          </div>
        </div>
      </div>
      <div class="multi-coin-right">
        <img [matMenuTriggerFor]="btcMenu" src="assets/icons/menu/dotted-menu.png" height="15" width="15" alt=""/>
      </div>
    </div>

    <div *ngIf="selectedAccount.multiWallet && selectedAccount.multiKeys.eth" class="multi-coin-container" style="display: flex; justify-content: space-between">
      <div class="multi-coin-left" style="display: flex;">
        <div class="multi-coin-icon">
          <img src="assets/icons/multi-coin/eth-icon.svg" height="40" width="40" alt=""/>
        </div>
        <div class="multi-coin-info">
          <div class="multi-coin-name">Ethereum</div>
          <div class="multi-coin-balance" matTooltip="{{ formatBalance(getEthBalance().toString()) }} ETH">
            <div class="hide-long-text">{{ formatBalance(getEthBalance().toString()) }}</div> ETH
          </div>
        </div>
      </div>
      <div class="multi-coin-right">
        <img [matMenuTriggerFor]="ethMenu" src="assets/icons/menu/dotted-menu.png" height="15" width="15" alt=""/>
      </div>
    </div>

    <div *ngIf="selectedAccount.multiWallet && selectedAccount.multiKeys.eth" class="multi-coin-container" style="display: flex; justify-content: space-between">
      <div class="multi-coin-left" style="display: flex;">
        <div class="multi-coin-icon">
          <img src="assets/icons/multi-coin/usdt-icon.svg" height="40" width="40" alt=""/>
        </div>
        <div class="multi-coin-info">
          <div class="multi-coin-name">Tether USD ( ERC20 )</div>
          <div class="multi-coin-balance" matTooltip="{{ formatBalance(getUSDTBalance().toString()) }} USDT">
            <div class="hide-long-text">{{ formatBalance(getUSDTBalance().toString()) }}</div> USDT
          </div>
        </div>
      </div>
      <div class="multi-coin-right">
        <img [matMenuTriggerFor]="ethMenu" src="assets/icons/menu/dotted-menu.png" height="15" width="15" alt=""/>
      </div>
    </div>

    <div *ngIf="selectedAccount.multiWallet && selectedAccount.multiKeys.tron" [class.hidden]="!showMoreToken" class="multi-coin-container"
         style="display: flex; justify-content: space-between">
      <div class="multi-coin-left" style="display: flex;">
        <div class="multi-coin-icon">
          <img src="assets/icons/multi-coin/trx-icon.svg" height="40" width="40" alt=""/>
        </div>
        <div class="multi-coin-info">
          <div class="multi-coin-name">Tron</div>
          <div class="multi-coin-balance" matTooltip="{{ formatBalance(getTRXBalance().toString()) }} TRX">
            <div class="hide-long-text">{{ formatBalance(getTRXBalance().toString()) }}</div>
            TRX
          </div>
        </div>
      </div>
      <div class="multi-coin-right">
        <span [matMenuTriggerFor]="tronMenu" [matMenuTriggerData]="{ token: 'trx' }">
          <img src="assets/icons/menu/dotted-menu.png" height="15" width="15" alt=""/>
        </span>
      </div>
    </div>

    <div *ngIf="selectedAccount.multiWallet && !selectedAccount.multiKeys.tron" [class.hidden]="!showMoreToken" class="multi-coin-container"
         style="display: flex; justify-content: space-between;">
      <div class="multi-coin-left" style="display: flex;">
        <div class="multi-coin-icon">
          <img src="assets/icons/multi-coin/trx-icon.svg" height="40" width="40" alt=""/>
        </div>
        <div class="multi-coin-info">
          <div class="multi-coin-name">Tron</div>
          <div class="multi-coin-balance">
            <button class="btn-common"
                    (click)="onOpenUnlockDialog('TRX')">
              {{ 'active' | i18n}}
            </button>
          </div>
        </div>
      </div>
      <div class="multi-coin-right-lock">
        <img src="assets/icons/menu/dotted-menu.png" height="15" width="15" alt=""/>
      </div>
    </div>

    <div *ngIf="selectedAccount.multiWallet && selectedAccount.multiKeys.tron" [class.hidden]="!showMoreToken" class="multi-coin-container"
         style="display: flex; justify-content: space-between">
      <div class="multi-coin-left" style="display: flex;">
        <div class="multi-coin-icon">
          <img src="assets/icons/multi-coin/trx-usdt-icon.svg" height="40" width="40" alt=""/>
        </div>
        <div class="multi-coin-info">
          <div class="multi-coin-name">Tether USD ( TRC20 )</div>
          <div class="multi-coin-balance" matTooltip="{{ formatBalance(getTRXUSDTBalance().toString()) }} USDT">
            <div class="hide-long-text">{{ formatBalance(getTRXUSDTBalance().toString()) }}</div>
            USDT
          </div>
        </div>
      </div>
      <div class="multi-coin-right">
        <span [matMenuTriggerFor]="tronMenu" [matMenuTriggerData]="{ token: 'trxusdt' }">
          <img src="assets/icons/menu/dotted-menu.png" height="15" width="15" alt=""/>
        </span>
      </div>
    </div>

    <div *ngIf="selectedAccount.multiWallet && !selectedAccount.multiKeys.tron" [class.hidden]="!showMoreToken" class="multi-coin-container"
         style="display: flex; justify-content: space-between;">
      <div class="multi-coin-left" style="display: flex;">
        <div class="multi-coin-icon">
          <img src="assets/icons/multi-coin/trx-usdt-icon.svg" height="40" width="40" alt=""/>
        </div>
        <div class="multi-coin-info">
          <div class="multi-coin-name">Tether USD ( TRC20 )</div>
          <div class="multi-coin-balance">
            <button class="btn-common"
                    (click)="onOpenUnlockDialog('TRXUSDT')">
              {{ 'active' | i18n}}
            </button>
          </div>
        </div>
      </div>
      <div class="multi-coin-right-lock">
        <img src="assets/icons/menu/dotted-menu.png" height="15" width="15" alt=""/>
      </div>
    </div>

    <!-- BSC coin  -->
    <!-- not active yet -->
    <div *ngIf="selectedAccount.multiWallet && !selectedAccount.multiKeys.bnb" [class.hidden]="!showMoreToken"
      class="multi-coin-container" style="display: flex; justify-content: space-between;">
      <div class="multi-coin-left" style="display: flex;">
        <div class="multi-coin-icon">
          <img src="assets/icons/multi-coin/bnb-icon.svg" height="40" width="40" alt="" />
        </div>
        <div class="multi-coin-info">
          <div class="multi-coin-name">Bnb</div>
          <div class="multi-coin-balance">
            <button class="btn-common" (click)="onOpenUnlockDialog('BNB')">
              {{ 'active' | i18n}}
            </button>
          </div>
        </div>
      </div>
      <div class="multi-coin-right-lock">
        <img src="assets/icons/menu/dotted-menu.png" height="15" width="15" alt="" />
      </div>
    </div>

    <div *ngIf="selectedAccount.multiWallet && selectedAccount.multiKeys.bnb" [class.hidden]="!showMoreToken"
      class="multi-coin-container" style="display: flex; justify-content: space-between">
      <div class="multi-coin-left" style="display: flex;">
        <div class="multi-coin-icon">
          <img src="assets/icons/multi-coin/bnb-icon.svg" height="40" width="40" alt="" />
        </div>
        <div class="multi-coin-info">
          <div class="multi-coin-name">Bnb</div>
          <div class="multi-coin-balance" matTooltip="{{ formatBalance(getBnbBalance().toString()) }} BNB">
            <div class="hide-long-text">{{ formatBalance(getBnbBalance().toString()) }}</div>
            BNB
          </div>
        </div>
      </div>
      <div class="multi-coin-right">
        <span [matMenuTriggerFor]="bscMenu" [matMenuTriggerData]="{ token: 'bnb' }">
          <img src="assets/icons/menu/dotted-menu.png" height="15" width="15" alt="" />
        </span>
      </div>
    </div>
    
    <div *ngIf="selectedAccount.multiWallet && !selectedAccount.multiKeys.bnb" [class.hidden]="!showMoreToken" class="multi-coin-container"
         style="display: flex; justify-content: space-between;">
      <div class="multi-coin-left" style="display: flex;">
        <div class="multi-coin-icon">
          <img src="assets/icons/multi-coin/usdt_bnb.svg" height="40" width="40" alt=""/>
        </div>
        <div class="multi-coin-info">
          <div class="multi-coin-name">Tether USD ( BEP20 )</div>
          <div class="multi-coin-balance">
            <button class="btn-common"
                    (click)="onOpenUnlockDialog('BEP')">
              {{ 'active' | i18n}}
            </button>
          </div>
        </div>
      </div>
      <div class="multi-coin-right-lock">
        <img src="assets/icons/menu/dotted-menu.png" height="15" width="15" alt=""/>
      </div>
    </div>

    <div *ngIf="selectedAccount.multiWallet && selectedAccount.multiKeys.bnb" [class.hidden]="!showMoreToken"
      class="multi-coin-container" style="display: flex; justify-content: space-between">
      <div class="multi-coin-left" style="display: flex;">
        <div class="multi-coin-icon">
          <img src="assets/icons/multi-coin/usdt_bnb.svg" height="40" width="40" alt="" />
        </div>
        <div class="multi-coin-info">
          <div class="multi-coin-name">Tether USD ( BEP20 )</div>
          <div class="multi-coin-balance" matTooltip="{{ formatBalance(getBepBnbBalance().toString()) }} USDT">
            <div class="hide-long-text">{{ formatBalance(getBepBnbBalance().toString()) }}</div>
            USDT
          </div>
        </div>
      </div>
      <div class="multi-coin-right">
        <span [matMenuTriggerFor]="bscMenu" [matMenuTriggerData]="{ token: 'bep' }">
          <img src="assets/icons/menu/dotted-menu.png" height="15" width="15" alt="" />
        </span>
      </div>
    </div>

    <a class="display-token" style="display: flex; justify-content: center; align-items: center;" (click)="showMoreToken = !showMoreToken">
      {{ !showMoreToken ? ('show_more' | i18n) : ('show_less' | i18n) }}
      <mat-icon *ngIf="!showMoreToken" style="color: #ED6D00;">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="showMoreToken" style="color: #ED6D00;">keyboard_arrow_up</mat-icon>
    </a>
  </div>

  <div class="navbar-content">
    <div class="border-line"></div>
    <fuse-navigation class="material2" layout="vertical" [navigation]="navigation"></fuse-navigation>
  </div>

</div>

