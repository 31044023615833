export const BEP20ABI = [
    {
        "constant": true,
        "inputs": [{ "name": "_owner", "type": "address" }],
        "name": "balanceOf",
        "outputs": [{ "name": "balance", "type": "uint256" }],
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            { "name": '_to', "type": 'address' },
            { "name": '_value', "type": 'uint256' },
        ],
        "name": 'transfer',
        "outputs": [{ "name": '', "type": 'bool' }],
        "type": 'function',
    }
]
