import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../../layout/components/i18n/i18n.service';
import { Amount } from '@fruitsjs/util';
import {formatNumber} from '@angular/common';
import {CurrencySymbol} from '@fruitsjs/util/src';

@Pipe({
  name: 'coinAmount',
  pure: false
})
export class CoinAmountPipe implements PipeTransform {

  private cachedLanguageCode: string;

  constructor(private i18nService: I18nService) {
  }

  private shouldUpdate(): boolean {
    return this.i18nService.currentLanguage.code !== this.cachedLanguageCode;
  }

  transform(value: string | number,
            inputType: 'planck' | 'burst' = 'burst',
            noUnit: boolean = false,
            coinType: CoinType = CoinType.frts
  ): string {
    if (this.shouldUpdate()) {

      const v = inputType === 'planck' ? Amount.fromPlanck(value).getSigna() : value;

      return formatAmount(v, {
        noUnit,
        locale: this.i18nService.currentLanguage.code,
        coinType: coinType
      });
    }
  }
}

export enum CoinType {
  frts = 'FRTS',
  btc = 'BTC',
  eth = 'ETH',
  usdt = 'USDT',
  erc20 = 'USDT_ERC',
  trc20 = 'USDT_TRC',
  bnb = 'BNB',
  bep20 = 'USDT_BEP',
}

export interface IAmountFormattingOptions {
  noUnit: boolean;
  locale: string;
  coinType: CoinType;
}

const DefaultFormattingOptions: IAmountFormattingOptions = {
  noUnit: false,
  locale: 'en',
  coinType: CoinType.frts
};

export function formatAmount(value: string | number, formattingOptions: IAmountFormattingOptions = DefaultFormattingOptions): string {

  const v = typeof value === 'string' ? parseFloat(value) : value;
  const { noUnit, locale, coinType } = formattingOptions;
  let digitsInfo = '1.0-8';
  switch (coinType) {
    case CoinType.frts: {
      digitsInfo = '1.0-8';
      break;
    }
    case CoinType.btc: {
      digitsInfo = '1.0-8';
      break;
    }
    case CoinType.eth: {
      digitsInfo = '1.0-18';
      break;
    }
  }
  const unit = noUnit ? '' : ' ' + coinType;
  return `${formatNumber(v, locale, digitsInfo)}${unit}`;
}

export const formatBalance = (balance: string, coinType: string): string => {
  let str = '';
  // If the number is not in scientific notation return it as it is.
  if (!/\d+\.?\d*e[+-]*\d+/i.test(balance)) {
    return balance;
  }
  switch (coinType.toUpperCase()) {
    case 'BTC':
    case 'FRTS': {
      str = parseFloat(balance).toFixed(8).replace(/\.?0+$/, '').toString();
      break;
    }
    case 'ETH': {
      str = parseFloat(balance).toFixed(18).replace(/\.?0+$/, '').toString();
      break;
    }
  }
  const intPart = str.substr(0, str.indexOf('.'));
  return intPart ? intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + str.substr(str.indexOf('.'))
    : str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
