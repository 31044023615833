import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CreateService, StepsEnum} from '../../create.service';
import {NotifierService} from 'angular-notifier';
import {I18nService} from '../../../../layout/components/i18n/i18n.service';
import {StoreService} from '../../../../store/store.service';
import {Settings} from '../../../../settings';
import {constants} from '../../../../constants';
import {environment} from '../../../../../environments/environment';
import {Account} from '@fruitsjs/core';
import {AppService} from '../../../../app.service';
import {ApiService} from '../../../../api.service';

@Component({
  selector: 'app-account-create-pin',
  styleUrls: ['./pin.component.scss'],
  templateUrl: './pin.component.html'
})
export class AccountCreatePinComponent {

  @Input() lastStep: boolean;
  pin: string;
  settings: Settings;
  queryParams: any;
  collectionUrl = environment.isMainNet ? constants.NFT_COLLECTION_MAINNET : constants.NFT_COLLECTION_TESTNET;
  status;
  callBackUrl;

  constructor(private router: Router,
              private createService: CreateService,
              private notificationService: NotifierService,
              private i18nService: I18nService,
              private storeService: StoreService,
              private activatedRoute: ActivatedRoute,
              private appService: AppService,
              private apiService: ApiService) {
    this.storeService.getSettings().then(settings => {
      this.settings = settings;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params) {
        this.queryParams = params;
      }
    });
  }

  public back(): void {
    this.createService.previousStep();
  }

  public next(): void {
    if (!this.isValid) {
      return;
    }

    this.createService.setPin(this.pin);
    setTimeout(() => {
      this.createService.setStep(StepsEnum.ActivateAccount);
    }, 0);
  }

  public get isValid(): boolean {
    return this.pin && this.pin.length >= 4;
  }

  async finish(): Promise<void> {
    if (!this.isValid) {
      return;
    }

    const settings = await this.storeService.getSettings();
    const isCreateMultiWallet = settings.isMultiWallet;

    const accounts = await this.storeService.getAllAccounts();
    const accountExist = accounts.map(account => account.accountRS).includes(this.createService.getAddress());
    if ((accountExist && isCreateMultiWallet && accounts.find(account => account.accountRS === this.createService.getAddress()).multiWallet)
      || (accountExist && !isCreateMultiWallet && !accounts.find(account => account.accountRS === this.createService.getAddress()).multiWallet)) {
      this.notificationService.notify('error', this.i18nService.getTranslation('account_imported'));
      const status = sessionStorage.getItem('status');
      if (status && status === 'home') {
        window.open(`${this.collectionUrl}`, '_self');
      }
      return;
    }

    this.createService.setPin(this.pin);
    this.createService.createActiveAccount().then(async (success) => {
        if (!success.confirmed) {
          const data = {
            activeRequests: [{
              'id': success.account,
              'publicKey': success.keys.publicKey
            }],
            remoteAddress: this.appService.getRemoteAddress()
          };
          this.apiService.activeAccount(data).subscribe(() => {}, () => {});
          success.activatorTime = new Date().getTime();
          await this.storeService.saveAccount(success);
        }

        this.notificationService.notify('success', this.i18nService.getTranslation('account_added'));
        this.createService.reset();

        this.status = sessionStorage.getItem('status');
        if (this.queryParams && Object.keys(this.queryParams).length > 0 && this.queryParams.redirect) {
          if (paymentRedirectUrl.includes(this.queryParams.redirect)) {
            await this.router.navigate(
              [this.queryParams.redirect],
              {queryParams: {paymentId: this.queryParams.paymentId}}
            );
          }
        } else if (this.status) {
          this.callBackUrl = decodeURIComponent(sessionStorage.getItem('callBack'));
          sessionStorage.removeItem('status');
          sessionStorage.removeItem('callBack');
          this.checkStatus(success);
        } else {
          this.router.navigate(['/']);
        }
      },
      (error) => {
        this.notificationService.notify('error', error.toString());
      });
  }

  checkStatus(account: Account): void {
    switch (this.status) {
      case 'sell':
      case 'cancel':
      case 'transfer':
      case 'transfer-detail':
      case 'create':
      case 'edit':
        window.open(`${this.collectionUrl}/nft/my-nfts?account=${account.accountRS}`, '_self');
        break;
      case 'connect':
        window.open(this.callBackUrl + `?account=${account.accountRS}&publicKey=${account.keys.publicKey}`, '_self');
        break;
      case 'home':
        window.open(`${this.collectionUrl}/save/account/${account.accountRS}?currentUrl=${encodeURIComponent(this.queryParams['currentUrl'])}`, '_self');
        break;
      default:
        this.router.navigate(['/']);
        break;
    }
  }
}

export const paymentRedirectUrl = ['send', 'send/btc', 'send/eth', 'send/payment'];
