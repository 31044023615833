import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UnlockCoin} from '../../../app/layout/components/navbar/vertical/style-1/style-1.component';
import {StoreService} from '../../../app/store/store.service';
import {Account} from '@fruitsjs/core';
import {decryptAES, hashSHA256} from '@fruitsjs/crypto';
import {I18nService} from '../../../app/layout/components/i18n/i18n.service';
import {NotifierService} from 'angular-notifier';
import {TronService} from '../../../app/multi-coin/tron.service';
import { BnbService } from 'app/multi-coin/bnb.service';

@Component({
  selector: 'unlock-dialog',
  templateUrl: './unlock-dialog.component.html',
  styleUrls: ['./unlock-dialog.component.scss']
})
export class UnlockDialogComponent {

  unlockCoin: { coin: string, name: string };
  isLoading = false;
  pin = '';

  constructor(public dialogRef: MatDialogRef<UnlockDialogComponent>,
              private storeService: StoreService,
              private i18nService: I18nService,
              private notifierService: NotifierService,
              private tronService: TronService,
              private bnbService: BnbService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.unlockCoin = {
      coin: this.data.unlockCoin,
      name: UnlockCoin[this.data.unlockCoin]
    };
  }

  canSubmit(): boolean {
    return this.pin && this.pin.length >= 1;
  }

  async unlock(): Promise<void> {
    this.isLoading = true;

    const account: Account = await this.storeService.getSelectedAccount();
    const passphrase = decryptAES(account.encryptedPassphrase, hashSHA256(this.pin));
    if (!passphrase) {
      this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
      this.isLoading = false;
      return;
    }

    if (UnlockCoin[this.data.unlockCoin] === UnlockCoin.TRXUSDT || UnlockCoin[this.data.unlockCoin] === UnlockCoin.TRX) {
      account.multiKeys.tron = await this.tronService.generateAccount(passphrase, this.pin);
      const newAccount = await this.storeService.saveAccount(account);
      this.storeService.setActive(true);
      this.notifierService.notify('success', this.i18nService.getTranslation('unlock_success').replace('__coin__', this.unlockCoin.name));
      this.isLoading = false;
      this.dialogRef.close(newAccount);
    }

    if (UnlockCoin[this.data.unlockCoin] === UnlockCoin.BNB || UnlockCoin[this.data.unlockCoin] === UnlockCoin.BEP) {
      account.multiKeys.bnb = await this.bnbService.generateKey(passphrase);
      const newAccount = await this.storeService.saveAccount(account);
      this.storeService.setActive(true);
      this.notifierService.notify('success', this.i18nService.getTranslation('unlock_success').replace('__coin__', this.unlockCoin.name));
      this.isLoading = false;
      this.dialogRef.close(newAccount);
    }

    this.isLoading = false;
    return;
  }
}
