import { version } from '../../package.json';
import {constants} from '../app/constants';

export const environment = {
  production: false,
  hmr: false,
  version,
  defaultNode: 'https://testnet.fwallet.net',
  market: {
    tickerUrl: 'https://api.coingecko.com/api/v3/coins/fruits',
    tickerEthUrl: 'https://api.coingecko.com/api/v3/coins/ethereum',
    tickerBtcUrl: 'https://api.coingecko.com/api/v3/coins/bitcoin',
    tickerInterval: 60 * 1000
  },
  coinMarketCap: {
    tickerUrl: constants.coinMarketCapURL + '/fruits/converter/api',
    tickerInterval: 60 * 1000
  },
  performance: {
    fcasUrl: 'https://platform-api.flipsidecrypto.com/api/v1/assets/573/metrics/FCAS?api_key=6e9c2808-0c48-41d8-81f1-5363d5ceb1af&change_over=7',
    fcasInterval: 60 * 5 * 1000
  },
  activatorServiceUrl: {
    mainNet: 'https://fruitscoin-account-activator.vercel.app/',
    testNet: 'https://fruitscoin-account-activator-testnet.vercel.app/'
  },
  isMainNet: false,
  isAdmin: true,
  isBeta: false,
  firebase: {
    apiKey: 'AIzaSyBcTfOwWSs4cucR0wxNdPOzf-kl1NhIGP4',
    authDomain: 'fruits-test-5aba6.firebaseapp.com',
    projectId: 'fruits-test-5aba6',
    storageBucket: 'fruits-test-5aba6.appspot.com',
    messagingSenderId: '682693683669',
    appId: '1:682693683669:web:fe747274cc24d48e83f55c',
    measurementId: 'G-CX3GD0C102'
  },
  TRONGRID_API_KEY: 'ab7429e3-5667-474f-8a27-9717393962c1',
  etherscanUrl: 'https://goerli.etherscan.io',
  toActiveAccountEnergy: '14650',
  blockstreamUrl: 'https://blockstream.info/testnet',
  traceUrl: 'https://api-test.fgtrace.com',
  fruitscanAccount: 'https://testnet.fruitscan.io/?action=account&account=',
  toNotActiveAccountEnergy: '29650',
  trxUsdtAddress: 'TXYZopYRdj2D9XRtbG411XZZ3kM5VkAeBf',
  tronUrl: 'https://nile.trongrid.io',
  tronscanUrl: 'https://nile.tronscan.org',
  ethUrl: 'https://sepolia.etherscan.io',
  btcUrl: 'https://blockstream.info/testnet',
  bnbUrl: 'https://bsc-testnet.infura.io/v3/ad8ffe85e5674df0b51c2d9d36e40e13',
  usdtBnbAddress: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
  bscScanUrl: 'https://testnet.bscscan.com',
};
