import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import {UnlockDialogComponent} from './unlock-dialog.component';
import {I18nModule} from '../../../app/layout/components/i18n/i18n.module';
import {FormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
    UnlockDialogComponent
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    I18nModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [
    UnlockDialogComponent
  ],
})
export class UnlockDialogModule
{
}
