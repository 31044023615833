import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {constants} from '../constants';
import {Amount} from '@fruitsjs/util';
import {TronKeys} from '@fruitsjs/core/src';
import {encryptAES, hashSHA256} from '@fruitsjs/crypto';

const TronWeb = require('tronweb');
const HttpProvider = TronWeb.providers.HttpProvider;

@Injectable({
  providedIn: 'root'
})
export class TronService {

  tronUrl = environment.tronUrl;
  trxUsdtAddress = environment.trxUsdtAddress;
  multiCoinUrl = environment.isMainNet ? constants.coinMarketCapURL : constants.multicoinTestURL;

  tronWeb = new TronWeb({
    fullHost: this.tronUrl,
    solidityNode: this.tronUrl,
    eventServer: this.tronUrl
  });

  getAccountResource = async (address: string) => {
    const url = `${this.tronUrl}/wallet/getaccountresource`;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('TRON-PRO-API-KEY', environment.TRONGRID_API_KEY);

    const response = await fetch(url, {
      method: 'POST',
      headers: myHeaders,

      body: JSON.stringify({
        address,
        visible: true
      })
    });
    return await response.json();
  }

  getChainParameter = async () => {
    const url = `${this.tronUrl}/wallet/getchainparameters`;

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('TRON-PRO-API-KEY', environment.TRONGRID_API_KEY);

    const response = await fetch(url, {
      method: 'GET',
      headers: myHeaders
    });
    return await response.json();
  }

  constructor(private http: HttpClient) {
  }

  async generateAccount(passphrase: string, pin: string): Promise<TronKeys> {
    const tronAccount: TronAccount = this.tronWeb.fromMnemonic(passphrase);
    const trxBalance = await this.getTrxBalance(tronAccount.address);
    const usdtBalance = await this.getUsdtBalance(tronAccount.address);
    return {
      address: tronAccount.address,
      signPrivateKey: encryptAES(tronAccount.privateKey.substring(2), hashSHA256(pin)),
      privateKey: tronAccount.privateKey.substring(2),
      publicKey: tronAccount.publicKey,
      balance: trxBalance,
      usdtBalance: usdtBalance
    };
  }

  async getTrxBalance(address: string): Promise<string> {
    const account = await this.tronWeb.trx.getAccount(address);
    return account && account.balance
      ? Promise.resolve(Amount.fromPlanck(account.balance).getRaw().dividedBy(Math.pow(10, constants.MAX_LENGTH_TRX)).toString())
      : Promise.resolve('0');
  }

  async getUsdtBalance(address: string): Promise<string> {
    await this.tronWeb.setAddress(this.trxUsdtAddress);
    const contract = await this.tronWeb.contract().at(this.trxUsdtAddress);
    const result = await contract.balanceOf(address).call();
    return Promise.resolve(Amount.fromPlanck(result.toString()).getRaw().dividedBy(Math.pow(10, constants.MAX_LENGTH_USDT)).toString());
  }

  async broadcastTx(signedTx): Promise<any> {
    return await this.tronWeb.trx.sendRawTransaction(signedTx);
  }

  async estimateEnergyConsumed(privateKey: string, fromAddress: string, toAddress: string, amountToSend: string): Promise<any> {
    const fullNode = new HttpProvider(this.tronUrl);
    const solidityNode = new HttpProvider(this.tronUrl);
    const eventServer = new HttpProvider(this.tronUrl);
    const tronWeb = new TronWeb(fullNode, solidityNode, eventServer, privateKey);
    const parameters = [
      {
        type: 'address',
        value: toAddress
      },
      {
        type: 'uint256',
        value: amountToSend
      }
    ];
    return await tronWeb.transactionBuilder.triggerConstantContract(this.trxUsdtAddress, 'transfer(address,uint256)', {}, parameters, fromAddress);
  }

  async getTransactionBandwidth(privateKey: string, toAddress: string, value: string): Promise<SignedTx> {
    const fullNode = new HttpProvider(this.tronUrl);
    const solidityNode = new HttpProvider(this.tronUrl);
    const eventServer = new HttpProvider(this.tronUrl);
    const tronWeb = new TronWeb(fullNode, solidityNode, eventServer, privateKey);

    const tx = await tronWeb.transactionBuilder.triggerSmartContract(this.trxUsdtAddress, 'transfer(address,uint256)',
      {
        callValue: 0
      },
      [{
        type: 'address',
        value: toAddress
      }, {
        type: 'uint256',
        value: value
      }]
    );

    const signedTx = await tronWeb.trx.sign(tx.transaction);
    const transactionByteLength = 9 + 60 + Buffer.from(signedTx.raw_data_hex, 'hex').byteLength + Buffer.from(signedTx.signature[0], 'hex').byteLength;
    return Promise.resolve({
      signedTx,
      bandwidth: transactionByteLength
    });
  }

  async calculateSendTrxFee(privateKey: string, to: string, amount: string): Promise<SignedTx> {
    const fullNode = new HttpProvider(this.tronUrl);
    const solidityNode = new HttpProvider(this.tronUrl);
    const eventServer = new HttpProvider(this.tronUrl);
    const tronWeb = new TronWeb(fullNode, solidityNode, eventServer, privateKey);
    const transaction = await tronWeb.transactionBuilder.sendTrx(to, amount);

    const signedTx = await tronWeb.trx.sign(transaction);
    return {
      signedTx: signedTx,
      bandwidth: 9 + 60 + Buffer.from(signedTx.raw_data_hex, 'hex').byteLength + Buffer.from(signedTx.signature[0], 'hex').byteLength
    };
  }

  async isAccountActivated(address: string): Promise<boolean> {
    const account = await this.tronWeb.trx.getAccount(address);
    return account && account.address;
  }

  async getTrxTransaction(address: string): Promise<any> {
    return this.http.get(`${this.multiCoinUrl}/fruits/multi-coin/api/trx-transactions?address=${address}`).toPromise();
  }

  async getTokenTransaction(address: string): Promise<any> {
    return this.http.get(`${this.multiCoinUrl}/fruits/multi-coin/api/trx-token-transactions?contract=${this.trxUsdtAddress}&address=${address}`).toPromise();
  }

  isValidAddress(address: string): boolean {
    return this.tronWeb.isAddress(address);
  }
}

interface TronAccount {
  address: string;
  mnemonic: Mnemonic;
  privateKey: string;
  publicKey: string;
}

interface Mnemonic {
  locale: string;
  path: string;
  phrase: string;
}

export interface TokenTransaction {
  transaction_id: string;
  status: number;
  block_ts: number;
  from_address: string;
  from_address_tag: any;
  to_address: string;
  to_address_tag: any;
  block: number;
  contract_address: string;
  quant: number;
  confirmed: boolean;
  contractRet: string;
  finalResult: string;
  revert: boolean;
  tokenInfo: TokenInfo;
  contract_type: string;
  fromAddressIsContract: boolean;
  toAddressIsContract: boolean;
}

export interface TokenInfo {
  tokenId: string;
  tokenAbbr: string;
  tokenName: string;
  tokenDecimal: number;
  tokenCanShow: number;
  tokenType: string;
  tokenLogo: string;
  tokenLevel: string;
  issuerAddr: string;
  vip: boolean;
}

export interface SignedTx {
  bandwidth: number;
  signedTx: {
    raw_data: any;
    raw_data_hex: string;
    signature: string[];
    txID: string;
    visible: boolean;
  };
}

export interface TrxTransaction {
  amount: string;
  block: number;
  block_timestamp: number;
  cheatStatus: boolean;
  confirmed: number;
  contractType: number;
  contract_ret: String;
  contract_type: string;
  decimals: number;
  direction: number;
  from: string;
  hash: string;
  issue_address: string;
  revert: 0;
  symbol: string;
  to: string;
  token_name: string;
}
