import {Component, OnInit} from '@angular/core';
import {Amount, CurrencySymbol} from '@fruitsjs/util/src';
import {StoreService} from '../../../store/store.service';
import {NotifierService} from 'angular-notifier';
import {I18nService} from '../../../layout/components/i18n/i18n.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AccountService} from '../../../setup/account/account.service';
import {NodeService} from '../../../main/node.service';
import {Account, Address} from '@fruitsjs/core';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {constants} from '../../../constants';
import {isKeyDecryptionError} from '../../../util/exceptions/isKeyDecryptionError';
import {decryptAES, hashSHA256, Keys} from '@fruitsjs/crypto/src';
import {KeyDecryptionException} from '../../../util/exceptions/KeyDecryptionException';
import {LockAction, NftService} from '../../nft.service';
import {NftStatus} from '../create-nft/create-nft.component';
import {getBalancesFromAccount} from '../../../util/balance';
import {forkJoin} from 'rxjs';
import {formatAmount} from "../../../utils";
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-sell-nft',
  templateUrl: './sell-nft.component.html',
  styleUrls: ['../nft-payment.scss', './sell-nft.component.scss']
})
export class SellNftComponent implements OnInit {

  maxAmount = 40000000000;
  nft: any;
  nftName: string;
  pin: string;
  price: any;
  fee = constants.nftMethodCallFee;
  account: Account;
  available;
  symbol = CurrencySymbol;
  collectionUrl = environment.isMainNet ? constants.NFT_COLLECTION_MAINNET : constants.NFT_COLLECTION_TESTNET;
  isLoading = false;
  orderResponse: any;
  params: any;
  swapRateUsd: any;
  usdPrice: any;
  usdAmount = 5;
  minimumFrts = 0;
  merchantId: any;
  quantity = 1;
  tokenType;
  listingPrice = 0;
  potentialEarnings = 0;
  floorFee = 5;
  format;
  url;
  threeDViewerUrl;
  accounts;
  totalQuantity;
  disabledSell = false;
  maxQuantitySell;
  maxPriceSell;
  showIconErr;
  node: any;

  constructor(private storeService: StoreService,
              private notifierService: NotifierService,
              private i18nService: I18nService,
              private http: HttpClient,
              private accountService: AccountService,
              private nodeService: NodeService,
              private route: ActivatedRoute,
              private router: Router,
              private nftService: NftService,
              private title: Title) {

    this.title.setTitle(constants.pageTitle + 'Sell NFT');

     this.storeService.getSettings().then((result: any) => {
      if (result && result.node) {
        this.node = result.node;
      }
    });

    this.storeService.getSelectedAccount().then(account => {
      this.account = account;

      this.route.queryParams.subscribe(async params => {
        if (params && params.orderId) {
          const rqOrderParams = new HttpParams().set('orderId', params.orderId);
          this.nftService.getNftOrder(rqOrderParams).subscribe(async rqOrderResponse => {
            if (rqOrderResponse && rqOrderResponse.errorCode === 1) {
              this.notifierService.notify('error', this.i18nService.getTranslation(rqOrderResponse.message));
              await this.router.navigate(['/']);
              return;
            }

            if (rqOrderResponse && rqOrderResponse.errorCode === 0) {
              this.orderResponse = rqOrderResponse.result;
              if (this.orderResponse.orderType !== OrderType[OrderType.SALE]) {
                this.notifierService.notify('error', this.i18nService.getTranslation('invalid_order_type'));
                await this.router.navigate(['/']);
              }
              this.params = JSON.parse(rqOrderResponse.result.params);
              this.nftName = decodeURIComponent(this.params.nft);
              this.tokenType = this.params.tokenType;
              await this.fetchData();
              await this.rateData(this.params.merchantId);
            }
          });
        } else {
          this.notifierService.notify('error', this.i18nService.getTranslation('order_not_found'));
          await this.router.navigate(['/']);
        }
      });
    });
  }

  ngOnInit(): void {
  }

  async rateData(merchantId): Promise<void> {
    const requestArr = [];
    requestArr.push(this.nftService.getFruitsRateToOtherCurrency(COINTYPE.usd));
    requestArr.push(this.nftService.getRate(merchantId));
    await forkJoin(requestArr).subscribe({
      next: (response: any) => {
        if (response[0].errorCode === 0 && response[1].code === 0) {
          this.usdPrice = Number(response[0].result.priceSelectedCurrency);

          // Rate
          this.swapRateUsd = response[1].data.filter((item: any) => item.targetCoinType === 'USD')[0] !== undefined
            ? response[1].data.filter((item: any) => item.targetCoinType === 'USD')[0].swapRate
            : 1;

          // calculate minimum frts
          this.minimumFrts = Number((this.usdAmount / this.swapRateUsd / this.usdPrice).toFixed(2));
        }
      },
      error: (err: any) => {
        console.warn(err);
      }
    });
  }

  async onSubmit(): Promise<void> {
    const validateForm = await this.validateForm();
    if (validateForm) {
      this.notifierService.notify('error', validateForm);
      return;
    }

    this.isLoading = true;
    this.tokenType === constants.SINGLE_TOKEN ? await this.putForSaleNft() : await this.putForSaleMultiToken();
  }

  // FRUITSC-2578
  async putForSaleNft(): Promise<void> {
    try {
      this.isLoading = true;
      if (this.nft.name) {
        const canSubmit = await this.nftService.isValidNFT(this.nft.name);
        if (!canSubmit) {
          this.isLoading = false;
          return;
        }
      }

      const data = new FormData();
      data.append('priceNQT', Amount.fromSigna(this.price).getPlanck());
      data.append('name', this.nft.name);
      data.append('publicKey', this.account.keys.publicKey);
      data.append('feeNQT', Amount.fromSigna(this.fee).getPlanck());
      data.append('deadline', constants.DEFAULT_DEADLINE.toString());
      data.append('createdBy', this.account.account);

      await this.nftService.putForSale(data).subscribe(async (response: any) => {
        if (response && response.errorCode === 1) {
          this.isLoading = false;
          this.notifierService.notify('error', this.i18nService.getTranslation(response.message));
        } else if (response && response.errorCode === 0) {
          const unsignedTransactionBytes = response.result;
          const {transaction} = await this.nodeService.signAndBroadcastTransaction(unsignedTransactionBytes,
            this.getSendersSignPrivateKey(this.pin, this.account.keys),
            this.account.keys.publicKey);

          if (transaction) {

            const transactionData = new FormData();
            transactionData.append('nftName', this.nft.name);
            transactionData.append('transactionId', transaction);
            transactionData.append('type', NftStatus[NftStatus.SALE]);
            transactionData.append('price', Amount.fromSigna(this.price).getPlanck().toString());
            transactionData.append('seller', this.account.account);
            await this.nftService.createNftTransaction(transactionData).subscribe(async (txResponse: any) => {
              if (txResponse && txResponse.errorCode === 1) {
                this.isLoading = false;
                this.notifierService.notify('error', this.i18nService.getTranslation(txResponse.message));
              }
            }, error => {
              this.isLoading = false;
              console.warn(error);
            });

            this.disabledSell = true;
            this.notifierService.notify('success', this.i18nService.getTranslation('sell_nft_successful'));
            const formSellAmount = new FormData();
            formSellAmount.append('name', this.nft.name);
            formSellAmount.append('price', this.price);
            await this.nftService.updateSellAmount(formSellAmount).subscribe(() => {
              setTimeout(() => {
                window.open(`${this.collectionUrl}/nft/my-nfts?account=${this.account.accountRS}&status=success`, '_self');
              }, 2000);
            }, () => {
              this.isLoading = false;
              this.disabledSell = false;
              this.notifierService.notify('error', this.i18nService.getTranslation('error_sell_amount'));
            });
          } else {
            this.isLoading = false;
            this.disabledSell = false;
            this.notifierService.notify('error', this.i18nService.getTranslation('error_sell_nft_1'));
          }
        } else {
          this.isLoading = false;
        }
      }, () => {
        this.isLoading = false;
        this.notifierService.notify('error', this.i18nService.getTranslation('error_sell_nft_1'));
      });

    } catch (e) {
      this.isLoading = false;
      if (isKeyDecryptionError(e)) {
        this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
      } else {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_sell_nft_1'));
      }
    } finally {
      if (this.nft.name) {
        try {
          await this.nftService.unlockNft(this.nft.name).toPromise();
        } catch (e) {
        }
      }
    }
  }


  // FRUITSC-2578
  async putForSaleMultiToken(): Promise<void> {

    const seller = Address.fromReedSolomonAddress(this.account.accountRS);
    const verify = await this.nftService.verifyAndLock({
      nftName: this.nft.name,
      seller: seller,
      buyer: null,
      quantity: Number(this.quantity),
      action: LockAction.SALE,
      offerId: null,
      node: this.node,
      sellerPublicKey: this.account.keys.publicKey,
      sellerPrivateKey: decryptAES(this.account.keys.signPrivateKey, hashSHA256(this.pin))
    });

    if (!verify) {
      this.isLoading = false;
      return;
    }

    try {
      const data: any = {
        tokenId: this.nft.aliasURI,
        pricePerUnit: Amount.fromSigna(this.price).getPlanck(),
        publicKey: this.account.keys.publicKey,
        feeNQT: Amount.fromSigna(this.fee).getPlanck(),
        deadline: constants.DEFAULT_DEADLINE,
        quantity: this.quantity,
        seller: this.account.account
      };

      await this.nftService.putForSaleMultiToken(data).subscribe(async (response: any) => {
        if (response && response.errorCode === 1) {
          this.isLoading = false;
          this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation(response.message));
          await this.callUnlock(seller, verify);
        } else if (response && response.errorCode === 0) {
          const unsignedTransactionBytes = response.result;
          const {transaction} = await this.nodeService.signAndBroadcastTransaction(unsignedTransactionBytes,
            this.getSendersSignPrivateKey(this.pin, this.account.keys),
            this.account.keys.publicKey);

          if (transaction) {

            const transactionData = new FormData();
            transactionData.append('nftName', this.nft.name);
            transactionData.append('transactionId', transaction);
            transactionData.append('type', NftStatus[NftStatus.SALE]);
            transactionData.append('quantity', this.quantity.toString());
            transactionData.append('price', Amount.fromSigna(this.price).getPlanck().toString());
            transactionData.append('seller', this.account.account);
            await this.nftService.createMultipleNftTransaction(transactionData).subscribe(async (txResponse: any) => {
              if (txResponse && txResponse.errorCode === 1) {
                this.isLoading = false;
                this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation(txResponse.message));
                await this.callUnlock(seller, verify);
              }
            }, error => {
              this.isLoading = false;
              this.callUnlock(seller, verify);
              console.warn(error);
            });

            this.disabledSell = true;
            this.notifierService.notify(constants.SUCCESS, this.i18nService.getTranslation('sell_nft_successful'));
            setTimeout(() => {
              window.open(`${this.collectionUrl}/nft/my-nfts?account=${this.account.accountRS}&status=success`, '_self');
            }, 2000);
          } else {
            this.isLoading = false;
            this.disabledSell = false;
            await this.callUnlock(seller, verify);
            this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation('error_sell_nft_1'));
          }
        } else {
          this.isLoading = false;
        }
        await this.callUnlock(seller, verify);
      }, () => {
        this.isLoading = false;
        this.callUnlock(seller, verify);
        this.notifierService.notify(constants.ERROR, this.i18nService.getTranslation('error_sell_nft_1'));
      });
    } catch (e) {
      this.isLoading = false;
      await this.callUnlock(seller, verify);
      if (isKeyDecryptionError(e)) {
        this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
      } else {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_sell_nft_1'));
      }
    }
  }

  async callUnlock(seller: any, verify: any): Promise<void> {
  // Unlock token if sale failed
      await this.nftService.unlockMultipleToken({
        nftName: this.nft.name,
        buyer: null,
        seller: seller.getNumericId(),
        lockId: verify.lockId,
        transaction: null,
        signature: verify.signature,
        message: seller.getNumericId()
        }).toPromise();
  }
  canSubmit(): boolean {
    if (this.isLoading) {
      return false;
    }
    if (this.maxPriceSell || this.maxQuantitySell) {
      return false;
    }
    return !!this.pin && this.pin.length >= 1 && !!this.price && ![0, '0'].includes(this.minimumFrts);
  }

  checkTotal(): boolean {
    return false;
  }

  async fetchData(): Promise<void> {
    this.tokenType === constants.SINGLE_TOKEN ? await this.fetchSingleTokenData() : await this.fetchMultiTokenData();

  }

  async fetchSingleTokenData(): Promise<void> {
    const rqParams = new HttpParams()
      .set('name', this.nftName)
      .set('collection', 'true');
    await this.nftService.findNftByName(rqParams).subscribe((res: any) => {
      if (res && res.errorCode === 0) {
        this.nft = res.result;
        this.url = this.nft.fileUrl;
        this.threeDViewerUrl = this.nft.thumbnail;
        this.format = this.nft.type === 0 ? constants.IMAGE : (this.nft.type === 1 ? constants.VIDEO : constants.THREE_DIMENSIONAL);
      } else {
        this.router.navigateByUrl('');
      }
    });
  }

  async fetchMultiTokenData(): Promise<void> {
    const rqParams = new HttpParams().set(constants.NFT_NAME, this.nftName);
    await this.nftService.findMultiNftByName(rqParams).subscribe((res: any) => {
      if (res && res.errorCode === 0) {
        this.nft = res.result;
        this.accounts = res.result.accounts.filter(a => a.account === this.account.account)[0];
        this.totalQuantity = this.accounts.quantityQNT;
        this.available = this.accounts.unconfirmedQuantityQNT;
        this.url = this.nft.fileUrl;
        this.threeDViewerUrl = this.nft.thumbnail;
        this.format = this.nft.type === 0 ? constants.IMAGE : (this.nft.type === 1 ? constants.VIDEO : constants.THREE_DIMENSIONAL);
      } else {
        this.router.navigateByUrl('');
      }
    });
  }

  calculatePrice(action: any): any {
    if (Number(this.quantity) < 1) {
      this.quantity = 1;
    }

    if (this.quantity % 1 !== 0) {
      return this.quantity = 1;
    }

    if (Number(this.quantity) > this.available) {
      this.quantity = this.available;
    }
    const royalties = this.nft.royalties == null ? 0 : this.nft.royalties;
    const quantity = Number(this.quantity) < 0 ? 0 : Number(this.quantity);

    this.listingPrice = Number(Number(Amount.fromSigna(quantity).multiply(this.price == null ? 0 : this.price).getSigna()).toFixed(4));
    this.potentialEarnings = Number((this.listingPrice - (this.listingPrice / 100 * (royalties + this.floorFee))).toFixed(4));
    if (this.listingPrice > this.maxAmount) {
      this.showIconErr = true;
      if (action === 0) {
        this.maxQuantitySell = Math.floor(this.maxAmount / this.price);
        this.maxPriceSell = null;
      } else {
        this.maxPriceSell = Math.floor(this.maxAmount / quantity);
        this.maxQuantitySell = null;
      }
      this.listingPrice = this.maxAmount;
      this.potentialEarnings = Number((this.listingPrice - (this.listingPrice / 100 * (royalties + this.floorFee))).toFixed(4));
    } else {
      this.maxQuantitySell = null;
      this.maxPriceSell = null;
      this.showIconErr = false;
    }
  }

  async validateForm(): Promise<string> {

    const privateKey = decryptAES(this.account.keys.agreementPrivateKey, hashSHA256(this.pin));
    if (!privateKey) {
      return this.i18nService.getTranslation('wrong_pin');
    }

 // validate main account is active
    let mainAccountActive = false;
    await this.accountService.getAccount(this.account.accountRS).then((account: any) => {
      return mainAccountActive = !(!account || !account.publicKey);

    }).catch(() => {
      mainAccountActive = false;
    });

    if (!mainAccountActive) {
      return this.i18nService.getTranslation('failed_sale_nft');
    }

    // validate price
    if (!this.price.toString() || this.price.toString().trim().length === 0) {
      return this.i18nService.getTranslation('error_price_1');
    }

    if (this.price.toString().endsWith('.')) {
      return this.i18nService.getTranslation('error_price_3');
    }

    const isValidPrice = Number(this.price);
    if (!isValidPrice && isValidPrice !== 0) {
      return this.i18nService.getTranslation('error_price_4');
    }

    const checkDecimals = this.price.toString().indexOf('.');
    if (checkDecimals !== -1) {
      const decimals = this.price.toString().substr(this.price.toString().indexOf('.') + 1);
      if (decimals.length > 8) {
        return this.i18nService.getTranslation('error_price_5');
      }
    }

    if (Amount.fromPlanck(this.price.toString()).getRaw().isGreaterThan(5E9)) {
      return this.i18nService.getTranslation('error_price_6');
    }

    // validate multiple token price
    if (this.tokenType === constants.MULTIPLE_TOKEN) {

      // validate quantity
      const isValidQuantity = Number(this.quantity);

      if (/^[-+]?[0-9]+\.[0-9]+$/.test(this.quantity.toString())) {
        return this.i18nService.getTranslation('error_quantity_1');
      }

      if (this.available === 0) {
         return this.i18nService.getTranslation('nft_quantity_incorrect');
      }

      if (!isValidQuantity && isValidQuantity !== 0) {
        return this.i18nService.getTranslation('error_quantity_4');
      }
      if (!Amount.fromPlanck(this.quantity).getRaw().isGreaterThan(0)) {
        return this.i18nService.getTranslation('error_quantity_2.2');
      }

      if (this.quantity > this.available) {
        return this.i18nService.getTranslation('error_quantity_2.1') + this.available;
      }

      if (!Amount.fromPlanck(this.quantity).getRaw().isGreaterThan(0)) {
        return this.i18nService.getTranslation('error_quantity_2.2');
      }

      const isValidMultiTokenPrice = Number(this.price);
      if (isValidMultiTokenPrice < this.minimumFrts) {
        return this.i18nService.getTranslation('error_nft_price_1.1') + formatAmount(String(this.minimumFrts)) + ' FRTS';
      }

      return '';
    }

    // validate single token price
    if (isValidPrice < this.minimumFrts) {
      return this.i18nService.getTranslation('error_nft_price_1.1') + formatAmount(String(this.minimumFrts)) + ' FRTS';
    }

    // @ts-ignore
    const rqParams = new HttpParams()
      .set('name', this.nftName)
      .set('collection', 'true');
    const {result} = await this.nftService.findNftByName(rqParams).toPromise();
    if (result && result.priceNQT) {
      return this.i18nService.getTranslation('error_nft_on_sale');
    }

    return '';
  }

  getSendersSignPrivateKey(pin: string, keys: Keys): string {
    const privateKey = decryptAES(keys.signPrivateKey, hashSHA256(pin));
    if (!privateKey) {
      throw new KeyDecryptionException();
    }
    return privateKey;
  }

  onCancel(): void {
     history.back();
  }

  getBalance(): string {
    return getBalancesFromAccount(this.account).availableBalance.getSigna();
  }

  formatQuantity(quantity: any): string {
    if (!quantity) {
      return '0';
    }
    return formatAmount(quantity.toString());
  }

}

export enum OrderType {
  CONNECT, CREATE, EDIT, BUY, SALE, CANCEL, TRANSFER,INCREASE_QUANTITY
}

export enum COINTYPE {
  frts = 'FRTS',
  btc = 'BTC',
  eth = 'ETH',
  paypal = 'PAYPAL',
  usd = 'USD',
  gt = 'GT'
}
