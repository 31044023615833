import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {constants} from '../../../constants';
import {Router} from '@angular/router';
import { StoreService } from 'app/store/store.service';


@Component({
  selector: 'request-selector',
  templateUrl: './request-selector.component.html',
  styleUrls: ['./request-selector.component.scss']
})
export class RequestSelectorComponent implements OnInit {

  @Input() coin;
  @Input() redirectTo;
  @Input() account;

  coinList = [];
  coinListCopy = [];
  coinFilter = '';
  coinSelected: any = {};

  constructor(private router: Router,
    private storeService: StoreService
  ) {
  }

  filterCoin(event): void {
    if (event.target.value) {
      this.coinList = this.coinListCopy.filter(coin => coin.name.toLowerCase().includes(event.target.value.toLowerCase())
        || coin.symbol.toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.coinList = this.coinListCopy;
    }
  }

  onCoinChange(event): void {
    this.router.navigate([this.redirectTo === 'request' ? event.value.request : event.value.send]);
  }

  ngOnInit(): void {
    this.storeService.active.subscribe(async () => {
      this.coinList = constants.coinList;
      if (this.account.multiWallet) {
        if (!this.account.multiKeys.bnb) {
          this.coinList = this.coinList.filter(item => item.network !== 'bnb')
        }
        if (!this.account.multiKeys.tron) {
          this.coinList = this.coinList.filter(item => item.network !== 'tron')
        }
      }
      this.coinListCopy = this.coinList;
      this.coinSelected = this.coinList.find(coin => coin.symbol === this.coin);
    });
  }
}
