<div class="inter confirm-dialog p-24">
  <div matDialogTitle style="font-weight: 700; font-size: 24px; color: #171A22;">
    {{ ('create_network_account' | i18n).replace('__coin__', unlockCoin.name) }}
  </div>
  <div mat-dialog-content style="width: 100%">
    <div style="text-align: center; font-weight: 500; font-size: 14px; color: #788393;">{{ 'unlock_warning' | i18n }}</div>
    <mat-form-field style="width: 100%; margin-top: 20px;" floatLabel="always" appearance="outline">
      <mat-label>{{ 'enter_pin' | i18n }}<span style="color:red"> *</span></mat-label>
      <input type="password" matInput [(ngModel)]="pin" name="pin">
    </mat-form-field>
  </div>
  <div mat-dialog-actions style="padding: 0 0 20px;">
    <button class="{{ !isLoading ? 'btn-common' : 'btn-common-disable' }}" [mat-dialog-close]="false">{{ 'cancel' | i18n }}</button>
    <button class="{{ !isLoading && canSubmit() ? 'btn-common' : 'btn-common-disable' }}" [disabled]="isLoading || !canSubmit()" (click)="unlock()">
      {{ 'confirm' | i18n }}
      <mat-icon *ngIf="isLoading" style="vertical-align: middle;">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-icon>
    </button>
  </div>
</div>

