import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Account } from '@fruitsjs/core';
import { NgForm } from '@angular/forms';
import { I18nService } from 'app/layout/components/i18n/i18n.service';
import { StoreService } from '../../../../store/store.service';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../../util/UnsubscribeOnDestroy';
import { Amount, BNBSymbol, USDTSymbol } from '@fruitsjs/util';
import { ActivatedRoute, Router } from '@angular/router';
import { constants } from '../../../../constants';
import jsQR from 'jsqr';
import { NotifierService } from 'angular-notifier';
import { TimeCreatedService } from '../../../../multi-coin/time-created.service';
import { IOrderDetail, PaymentGatewayService } from '../../payment-gateway.service';
import { environment } from '../../../../../environments/environment';
import { formatBalance } from '../../../../util/formatAmount';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import {
  WarnSendFromPaymentGatewayDialogComponent
} from '../../warn-send-from-payment-gateway-dialog/warn-send-from-payment-gateway-dialog.component';
import { Settings } from '../../../../settings';
import { IUnlockMultipleToken } from '../../../../payment-gateway/nft.service';
import { formatBalances } from '../../../../util/util';
import { BroadcastData } from '../../../../multi-coin/btc.service';
import { decryptAES, hashSHA256 } from '@fruitsjs/crypto/src';
import { CoinType } from '../../../../shared/pipes/coin-amount.pipe';
import { BnbService } from 'app/multi-coin/bnb.service';

const isNotEmpty = (value: string) => value && value.length > 0;
let nextId = 0;

@Component({
  selector: 'app-send-bnb-usdt-form',
  templateUrl: './send-bnb-usdt-form.component.html',
  styleUrls: ['./send-bnb-usdt-form.component.scss']
})
export class SendBnbUsdtFormComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  multiCoinUrl = environment.isMainNet ? constants.coinMarketCapURL : constants.multicoinTestURL;

  @ViewChild('sendUsdtForm', { static: true }) public sendUsdtForm: NgForm;
  @ViewChild('amount', { static: true }) public amount: string;
  @ViewChild('gas', { static: true }) public gas: any;
  @ViewChild('file', { static: false }) file: ElementRef;
  @ViewChild('pin', { static: true }) public pin: string;

  @Input() account: Account;

  public unSubscriber = takeUntil(this.unsubscribeAll);

  public recipientAddress: string;
  fileId = `file-${nextId++}`;

  public maxFee = '0';
  priorityIndex = 1;
  isSending = false;
  isGettingData = false;
  language: string;
  estimateGas = [
    {
      index: 0,
      priority: 'Low',
      value: '1'
    },
    {
      index: 1,
      priority: 'Medium',
      value: '1.5'
    },
    {
      index: 2,
      priority: 'High',
      value: '2'
    }
  ];
  gasLimit = constants.transferTokenMaxGasLimit;
  maxGasFee;
  baseFee;
  maxPriority;
  fetchData;

  balances = '0';
  bnbBalances = '0';
  symbol = USDTSymbol;
  bnbSymbol = BNBSymbol;
  advanced = false;
  disableInputField = false;
  paymentId: string;

  settings: Settings;
  requestCurrency = constants.requestCurrency;
  currencyListUsdt;
  currencySelectedUsdt;
  currencyListCopy;
  priceUsdt;
  isSelectingUsdt = false;
  priceCurrency;
  changeUsdt;
  maxlength;
  key;
  total;
  decimals = constants.MAX_LENGTH_ETH;
  node: any;
  tokenName: string;
  gasPrice;

  constructor(private i18nService: I18nService,
    private storeService: StoreService,
    private router: Router,
    private route: ActivatedRoute,
    private notifierService: NotifierService,
    private gatewayService: PaymentGatewayService,
    private timeCreatedService: TimeCreatedService,
    private warnDialog: MatDialog,
    private bnbService: BnbService,
    private http: HttpClient) {
    super();
    this.storeService.settings
      .pipe(
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(async ({ language }) => {
        this.language = language;
      });
    this.storeService.getSettings().then((settings: any) => {
      this.settings = settings;
    });
    this.storeService.getSettings().then((result: any) => {
      if (result && result.node) {
        this.node = result.node;
      }
    });
  }

  ngOnInit(): void {
    if (!this.account.multiWallet) {
      this.router.navigate(['/dashboard']);
      this.warnDialog.open(WarnSendFromPaymentGatewayDialogComponent, {
        width: '500px'
      });
    }

    this.getData().then(() => {
      if (this.route.snapshot.queryParams) {
        setTimeout(async () => {
          this.paymentId = this.route.snapshot.queryParams.paymentId;
          await this.getPaymentOrder(this.paymentId);
        }, 500);
      }

      this.currencyListUsdt = constants.currencies.map(currency => this.requestCurrency.includes(currency.code) ? currency : undefined)
        .filter(currency => currency !== undefined);
      this.currencySelectedUsdt = this.settings && this.settings.currency && this.settings.currency.usdt
        ? this.currencyListUsdt.filter(item => item.code === this.settings.currency.usdt)[0] : this.currencyListUsdt[0];
      this.currencyListCopy = this.currencyListUsdt;
      this.getPriceUsdt();
    });

    this.fetchData = setInterval(() => {
      this.getData();
    }, 10000);

  }

  async getPriceUsdt(): Promise<void> {
    if (this.currencySelectedUsdt) {
      const params = new HttpParams()
        .set('source', USDTSymbol)
        .set('destination', this.currencySelectedUsdt.code);
      this.http.get(this.multiCoinUrl + '/fruits/converter/api', {
        params: params
      }).subscribe((response: any) => {
        if (response && response.errorCode === 0) {
          this.priceUsdt = response.result;
          this.priceCurrency = this.priceUsdt.priceSelectedCurrency;
        }
        this.isSelectingUsdt = false;
      }, (e) => {
        console.warn(e);
        this.isSelectingUsdt = false;
      });
    }
  }

  async valueChange(newValue): Promise<void> {
    if (this.priceCurrency) {
      this.amount = newValue;
      const value = newValue ? (newValue.endsWith('.') ? newValue.replace('.', '') : newValue) : '0';
      this.changeUsdt = this.removeTrailingZeros(Amount.fromPlanck(value).getRaw().multipliedBy(Amount.fromPlanck(this.priceCurrency).getRaw()).toFixed(4));
    }

    await this.estimateGasUsed();
  }

  ngOnDestroy(): void {
    clearInterval(this.fetchData);
  }

  async getData(): Promise<void> {
    this.isGettingData = true;

    if (this.account.multiKeys) {
      this.balances = this.account.multiKeys.bnb.usdtBalance;
      this.bnbBalances = this.account.multiKeys.bnb.balance;
    }

    await this.estimateGasUsed();

    await this.bnbService.getFeeHistory().then(async result => {
      const blocks = await this.formatOutput(result);

      this.estimateGas[0].value = await this.bnbService.toGwei(this.avg(blocks.map(b => b.priorityFeePerGas[0])));
      this.estimateGas[1].value = await this.bnbService.toGwei(this.avg(blocks.map(b => b.priorityFeePerGas[1])));
      this.estimateGas[2].value = await this.bnbService.toGwei(this.avg(blocks.map(b => b.priorityFeePerGas[2])));

      this.gas = this.estimateGas[this.priorityIndex];
    });

    await this.bnbService.getLatestBlock().then(async block => {
      this.baseFee = await this.bnbService.toGwei(block.baseFeePerGas.toString());
      const gasPrice = await Amount.fromPlanck(this.gas.value).getRaw().plus(Amount.fromPlanck(this.baseFee).getRaw()).toString();
      this.gasPrice = Amount.fromPlanck(gasPrice).getRaw().multipliedBy(10E8).toString();
      this.maxFee = await this.removeTrailingZeros(Amount.fromPlanck(this.gasLimit).getRaw()
        .multipliedBy(Amount.fromPlanck(gasPrice).getRaw()).dividedBy(10E8).toFixed(18));
      this.maxGasFee = await Amount.fromPlanck(this.baseFee).getRaw().plus(this.gas.value).multipliedBy(10E8).toString();
      this.maxPriority = await (this.gas.value * 10E8).toString();
    });

    this.isGettingData = false;
  }

  async estimateGasUsed(): Promise<void> {
    const value = this.amount ? (this.amount.endsWith('.') ? this.amount.replace('.', '') : this.amount) : '0';
    const data = {
      from: this.account.multiKeys.bnb.address,
      to: this.recipientAddress,
      amount: value ? Amount.fromPlanck(value).getRaw().multipliedBy(Math.pow(10, this.decimals)).toString() : ''
    };
    this.gasLimit = await this.bnbService.estimateGas(data).catch(() => {
      return constants.transferTokenMaxGasLimit;
    });

    if (this.gasPrice) {
      const gasPrice = Amount.fromPlanck(this.gasPrice).getRaw().dividedBy(10E8).toString();
      this.maxFee = await this.removeTrailingZeros(Amount.fromPlanck(this.gasLimit).getRaw()
        .multipliedBy(Amount.fromPlanck(gasPrice).getRaw()).dividedBy(10E8).toFixed(18));
    }
  }

  public removeTrailingZeros(s: string): string {
    return s.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');
  }

  public formatOutput(result): any {
    let blockNum = this.bnbService.hexToNumber(result.oldestBlock);
    let index = 0;
    const blocks = [];
    while (blockNum < this.bnbService.hexToNumber(result.oldestBlock) + 1) {
      blocks.push({
        number: blockNum,
        baseFeePerGas: Number(result.baseFeePerGas[index]),
        gasUsedRatio: Number(result.gasUsedRatio[index]),
        priorityFeePerGas: result.reward[index].map(x => Number(x)),
      });
      blockNum += 1;
      index += 1;
    }
    return blocks;
  }

  public avg(arr): string {
    const sum = arr.reduce((a, v) => a + v);
    return Math.round(sum / arr.length).toString();
  }

  getTotal(): string {
    try {
      return (this.amount && Number(this.amount) && this.amount !== '0')
        ? Amount.fromPlanck(this.amount).getRaw().toString()
        : '0';
    } catch (e) {
      return '0';
    }
  }

  getTotalBnb(): string {
    return this.maxFee;
  }

  async onSubmit(event): Promise<void> {
    event.stopImmediatePropagation();

    const validateForm = this.validateForm();
    if (validateForm) {
      this.notifierService.notify('error', validateForm);
      this.isSending = false;
      return;
    }

    const privateKey = decryptAES(this.account.keys.signPrivateKey, hashSHA256(this.pin));
    if (!privateKey) {
      this.notifierService.notify('error', this.i18nService.getTranslation('wrong_pin'));
      this.isSending = false;
      return;
    }

    // Check payment gateway
    let order;
    let lockData;
    let unlockData: IUnlockMultipleToken;
    if (this.paymentId) {
      const paymentOrder = await this.gatewayService.isValidNFTOrder(
        this.paymentId,
        this.account.keys.publicKey,
        null,
        this.account.account,
        this.node
      );

      if (!paymentOrder) {
        return;
      }

      if (paymentOrder && !paymentOrder.isDonate) {
        if (paymentOrder && paymentOrder.orderDetail) {
          order = paymentOrder.orderDetail.data;
          this.tokenName = paymentOrder.nftName;
        }

        if (order.multiple) {
          lockData = paymentOrder.lock;
          unlockData = {
            nftName: this.tokenName,
            message: this.account.account,
            signature: lockData.signature,
            lockId: lockData.lockId,
            seller: this.account.account,
            buyer: order.nftName,
            transaction: null
          };
        }
      }
    }

    try {
      const checkTime = await this.timeCreatedService.checkTimeCreated(this.account.multiKeys.bnb.address);
      await this.timeCreatedService.updateTimeCreated(this.account.multiKeys.bnb.address);
      if (!checkTime.result) {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_time_created'));
        return;
      }

      this.isSending = true;
      const data = {
        toAddress: this.recipientAddress,
        privateKey: this.account.multiKeys.bnb.privateKey,
        amount: Amount.fromPlanck(this.amount).getRaw().multipliedBy(Math.pow(10, this.decimals)).toString(),
        gas: this.gasLimit,
        gasPrice: this.gasPrice
      };

      this.bnbService.sendUSDT(data).then(async (result) => {
        if (result.rawTransaction) {
          const broadcastData = {
            signedTx: result.rawTransaction,
          };

          const signedTransaction = await this.bnbService.broadcastTransaction(broadcastData);
          if (signedTransaction && signedTransaction.result && signedTransaction.result['tx-hash']) {
            this.notifierService.notify('success', this.i18nService.getTranslation('send_coin_success').split('__coin__').join('USDT'));
            this.isSending = false;

            if (this.paymentId) {
              this.gatewayService.updateTransactionId(
                this.paymentId,
                signedTransaction.data.item.transactionId,
                this.account.accountRS,
                order && order.multiple ? unlockData.lockId : null
              ).subscribe((res: any) => {
                if (res.code === 0) {
                  window.location.href = `${environment.isMainNet ? constants.paymentStatusMainnet : constants.paymentStatusTestnet}/#/buyer/transaction-status/${this.paymentId}`;
                } else {
                  this.notifierService.notify('error', this.i18nService.getTranslation('payment_connect_error'));
                }
              });
            } else {
              await this.router.navigate(['/bnb-usdt-transactions']);
            }

          } else {
            // if transaction fail, check and unlock nft
            this.unlockToken(unlockData, order.multiple);
            this.notifierService.notify('error', this.i18nService.getTranslation('error_send_usdt'));
            this.isSending = false;
          }
        } else {
          this.isSending = false;
          this.notifierService.notify('error', this.i18nService.getTranslation('error_send_usdt'));
        }
      }).catch((e) => {
        console.log(e);
        // if transaction fail, check and unlock nft
        this.unlockToken(unlockData, order.multiple);
        this.isSending = false;
        this.notifierService.notify('error', this.i18nService.getTranslation('error_send_usdt'));
      });
    } catch (e) {
      this.isSending = false;
      this.notifierService.notify('error', this.i18nService.getTranslation('error_send_usdt'));
    }
  }

  async unlockToken(unlockData: any, isErc1155: boolean): Promise<void> {
    if (this.tokenName) {
      try {
        if (isErc1155) {
          await this.gatewayService.unlockMultipleToken(unlockData);
        } else {
          await this.gatewayService.unlockNft(this.tokenName).toPromise();
        }
      } catch (ex) {
      }
    }
  }

  hasSufficientBalance(): boolean {
    return Amount.fromPlanck(this.getTotal()).getRaw().isLessThanOrEqualTo(Amount.fromPlanck(this.balances).getRaw());
  }

  hasSufficientBalanceBnb(): boolean {
    return Amount.fromPlanck(this.getTotalBnb()).getRaw().isLessThanOrEqualTo(Amount.fromPlanck(this.bnbBalances).getRaw());
  }

  canSubmit(): boolean {
    const amount = this.amount && this.amount.endsWith('.') ? this.amount.replace('.', '') : this.amount;
    return isNotEmpty(this.recipientAddress)
      && isNotEmpty(amount)
      && isNotEmpty(this.pin)
      && !Amount.fromPlanck(amount).getRaw().eq(0)
      && this.hasSufficientBalance()
      && this.hasSufficientBalanceBnb();
  }

  validateForm(): string {
    this.isSending = true;
    if (!this.bnbService.isValidAddress(this.recipientAddress)) {
      return this.i18nService.getTranslation('invalid_eth_address');
    }

    const isValidAmount = Number(this.amount);
    if (!isValidAmount && isValidAmount !== 0) {
      return this.i18nService.getTranslation('invalid_eth_amount');
    }
    if (isValidAmount < 0) {
      return this.i18nService.getTranslation('invalid_eth_amount');
    }

    try {
      // check if amount has scientific notation format
      Amount.fromPlanck(this.amount);
    } catch (e) {
      return this.i18nService.getTranslation('invalid_eth_amount');
    }

    const checkDecimals = this.amount.indexOf('.');
    if (checkDecimals !== -1) {
      const decimals = this.amount.substr(this.amount.indexOf('.') + 1);
      if (decimals.length > 6) {
        return this.i18nService.getTranslation('error_price_9');
      }
    }

    return '';
  }

  async onGasChanged(): Promise<void> {
    this.gas = await this.gas ? this.gas : this.estimateGas[1];
    this.priorityIndex = this.estimateGas.find(item => item.priority === this.gas.priority).index;
    const gasPrice = await this.gas ? Amount.fromPlanck(this.gas.value).getRaw().plus(Amount.fromPlanck(this.baseFee).getRaw()).toString() : '0';
    this.gasPrice = Amount.fromPlanck(gasPrice).getRaw().multipliedBy(10E8).toString();
    this.maxFee = await this.removeTrailingZeros(Amount.fromPlanck(this.gasLimit).getRaw()
      .multipliedBy(Amount.fromPlanck(gasPrice).getRaw()).dividedBy(10E8).toFixed(18));
    this.maxGasFee = await Amount.fromPlanck(this.baseFee).getRaw().multipliedBy(2).plus(this.gas.value).multipliedBy(10E8).toString();
    this.maxPriority = await this.gas.value * 10E8;
  }

  maxGasFeeToWei(): string {
    return this.maxGasFee ? Amount.fromPlanck(this.maxGasFee).getRaw().dividedBy(10E8).toString() : '0';
  }

  resetFile(event): void {
    event.target.value = null;
  }

  parseQR(): void {
    const file = this.file.nativeElement.files[0];
    if (!file) {
      return;
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {

      const width = img.naturalWidth,
        height = img.naturalHeight;

      const reader = new FileReader();
      reader.onload = (): void => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        let qrCodeImageFormat = ctx.getImageData(0, 0, width, height);
        let qr = jsQR(qrCodeImageFormat.data, qrCodeImageFormat.width, qrCodeImageFormat.height);
        if (!qr) {
          ctx.drawImage(img, 10, 10, width, height);
          qrCodeImageFormat = ctx.getImageData(0, 0, width, height);
          qr = jsQR(qrCodeImageFormat.data, qrCodeImageFormat.width, qrCodeImageFormat.height);
        }
        if (qr) {
          const urlText = qr.data;
          if (!urlText.startsWith('usdt')) {
            if (!this.bnbService.isValidAddress(urlText)) {
              this.notifierService.notify('error', this.i18nService.getTranslation('invalid_eth_address'));
              return;
            } else {
              this.recipientAddress = urlText;
              this.amount = '0';
              this.notifierService.notify('success', this.i18nService.getTranslation('qr_parse_success'));
            }

            // this.notifierService.notify('error', this.i18nService.getTranslation('error_qr_format'));
            // return;
          } else {
            this.recipientAddress = urlText.substring(urlText.indexOf(':') + 1, urlText.lastIndexOf('?'));
            const paramsObj = new URLSearchParams(urlText.substring(urlText.lastIndexOf('?') + 1));
            const amount = paramsObj.get('amount');
            if (paramsObj.get('paymentId')) {
              this.disableInputField = true;
              this.paymentId = paramsObj.get('paymentId');
            }
            this.amount = amount;
            this.notifierService.notify('success', this.i18nService.getTranslation('qr_parse_success'));
          }
        } else {
          this.notifierService.notify('error', this.i18nService.getTranslation('qr_parse_fail'));
        }
      };

      window.URL.revokeObjectURL(img.src);
      reader.readAsDataURL(file);
    };
  }


  getPaymentOrder(paymentId: string): void {
    if (!paymentId) {
      return;
    }
    this.gatewayService.getPaymentOrder(paymentId).subscribe(
      (res: any) => {
        if (res && res.code === 0) {

          const data: IOrderDetail = res.data;
          this.amount = formatBalance(data.remaining + '', 'USDT');
          this.recipientAddress = data.address;
          this.disableInputField = true;

        }
      },
      () => {
        this.notifierService.notify('error', this.i18nService.getTranslation('error_unknown'));
      }
    );
  }

  formatBalance(balance): string {
    return formatBalances(balance);
  }
}
