import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import 'hammerjs';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';

import {LayoutModule} from 'app/layout/layout.module';
import {MainComponent} from './main.component';
import {DashboardModule} from './dashboard/dashboard.module';
import {LoginGuard} from 'app/login/login-guard.service';
import {SendBurstModule} from './send-burst/send-burst.module';
import {AccountsModule} from './accounts/accounts.module';
import {SetAccountInfoModule} from './set-account-info/set-account-info.module';
import {SetRewardRecipientModule} from './set-reward-recipient/set-reward-recipient.module';
import {SetCommitmentModule} from './set-commitment/set-commitment.module';
import {MatPaginatorIntl} from '@angular/material';
import {I18nService} from '../layout/components/i18n/i18n.service';
import {PaginatorI18n} from '../paginator-i18n';
import {JuiceOrderModule} from './swap/juice-order.module';
import {UnlockDialogModule} from '../../@fuse/components/unlock-dialog/unlock-dialog.module';


const mainRoutes: Routes = [
  {
    path: '*',
    canActivate: [LoginGuard],
    redirectTo: 'dashboard'
  },
  {
    path: 'peers',
    loadChildren: () => import('./peers/peers.module').then(p => p.PeersModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule)
  },
  {
    path: 'blocks',
    loadChildren: () => import('./blocks/blocks.module').then(b => b.BlocksModule)
  },
  // {
  //   path: 'aliases',
  //   loadChildren: () => import('./aliases/aliases.module').then(a => a.AliasesModule)
  // },
  // {
  //   path: 'my-nfts',
  //   loadChildren: () => import('./nfts/nfts.module').then(a => a.NftsModule)
  // },
  // {
  //   path: 'nfts',
  //   loadChildren: () => import('./aliases-list/aliases-list.module').then(a => a.AccountNftsModule)
  // },
  // {
  //   path: 'utility',
  //   loadChildren: () => import('./utility/utility.module').then(a => a.UtilityModule)
  // },
  // {
  //   path: 'collection',
  //   loadChildren: () => import('./collection/collection.module').then(a => a.CollectionModule)
  // },
  {
    path: 'mytokens',
    loadChildren: () => import('./assets/assets.module').then(a => a.AssetsModule)
  },
  // {
  //   path: 'orders',
  //   loadChildren: () => import('./orders/orders.module').then(a => a.OrdersModule)
  // },
  {
    path: 'frts-transactions',
    loadChildren: () => import('./transactions/transactions.module').then(t => t.TransactionsModule)
  },
  {
    path: 'eth-transactions',
    loadChildren: () => import('./eth-transaction/transactions.module').then(t => t.TransactionsModule)
  },
  {
    path: 'btc-transactions',
    loadChildren: () => import('./btc-transaction/transactions.module').then(t => t.TransactionsModule)
  },
  {
    path: 'usdt-transactions',
    loadChildren: () => import('./usdt-transaction/transactions.module').then(t => t.TransactionsModule)
  },
  {
    path: 'request',
    loadChildren: () => import('./request-burst/request-burst.module').then(r => r.RequestBurstModule)
  },
  // {
  //   path: 'request-eth',
  //   loadChildren: () => import('./request-eth/request-eth.module').then(r => r.RequestEthModule)
  // },
  // {
  //   path: 'request-btc',
  //   loadChildren: () => import('./request-btc/request-btc.module').then(r => r.RequestBtcModule)
  // },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(s => s.SettingsModule)
  },
  {
    path: 'issue-token',
    loadChildren: () => import('./issue-token/issue-token.module').then(is => is.IssueTokenModule)
  },
  {
    path: 'issue-nft',
    loadChildren: () => import('./issue-nft/issue-nft.module').then(is => is.IssueNftModule)
  },
  // {
  //   path: 'contracts',
  //   loadChildren: () => import('./contracts/ats.module').then(s => s.ATModule)
  // },
  // {
  //   path: 'asset-payout',
  //   loadChildren: () => import('./asset-payout/asset-payout.module').then(ap => ap.AssetPayoutModule)
  // },
  {
    path: 'transfer-history',
    loadChildren: () => import('./transfer-history/transfer-history.module').then(is => is.TransferHistoryModule)
  },
  {
    path: 'swap-tokens',
    loadChildren: () => import('./swap-token/swap-token.module').then(s => s.SwapTokenModule)
  },
  {
    path: 'exchange',
    loadChildren: () => import('./exchange/asset-exchange.module').then(is => is.AssetExchangeModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notification/notification.module').then(is => is.NotificationModule)
  },
  // {
  //   path: 'banner',
  //   loadChildren: () => import('./banner/banner.module').then(is => is.BannerModule)
  // },
  {
    path: 'banner',
    loadChildren: () => import('./banner/banner.module').then(is => is.BannerModule)
  },
  {
    path: 'staking',
    loadChildren: () => import('./staking/staking.module').then(is => is.StakingModule)
  },
  {
    path: 'staking-history',
    loadChildren: () => import('./staking/history/staking-history.module').then(is => is.StakingHistoryModule)
  },
  {
    path: 'lending',
    loadChildren: () => import('./lending/lending.module').then(is => is.LendingModule)
  },
  {
    path: 'lending-history',
    loadChildren: () => import('./lending/history/lending-history.module').then(is => is.LendingHistoryModule)
  },
  {
    path: 'farming',
    loadChildren: () => import('./farming/farming.module').then(is => is.FarmingModule)
  },
  {
    path: 'farming-history',
    loadChildren: () => import('./farming/history/farming-history.module').then(is => is.FarmingHistoryModule)
  },
  {
    path: 'overview',
    loadChildren: () => import('./proposals-overview/overview.module').then(is => is.OverviewModule)
  },
  {
    path: 'my-proposals',
    loadChildren: () => import('./my-proposals/my-proposals.module').then(is => is.MyProposalsModule)
  },
  {
    path: 'nft',
    loadChildren: () => import('../payment-gateway/nft/nft.module').then(is => is.NftModule)
  },
  {
    path: 'nft/utility/create',
    loadChildren: () => import('./nft-utility/utility-nft.module').then(is => is.UtilityNftModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./open-swap-orders/orders.module').then(is => is.OrdersModule)
  },
  {
    path: 'nfts',
    loadChildren: () => import('./account-nfts/account-nfts.module').then(is => is.AccountNftsModule)
  },
  {
    path: 'trx-transactions',
    loadChildren: () => import('./trx-transaction/transactions.module').then(t => t.TransactionsModule)
  },
  {
    path: 'trx-usdt-transactions',
    loadChildren: () => import('./trx-usdt-transaction/transactions.module').then(t => t.TransactionsModule)
  },
  {
    path: 'bnb-transactions',
    loadChildren: () => import('./bnb-transaction/transactions.module').then(t => t.TransactionsModule)
  },
  {
    path: 'bnb-usdt-transactions',
    loadChildren: () => import('./bnb-usdt-transaction/transactions.module').then(t => t.TransactionsModule)
  },
];

@NgModule({
  declarations: [
    MainComponent,
  ],
  imports: [
    HttpClientModule,
    RouterModule.forChild(mainRoutes),

    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,

    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    DashboardModule,
    SendBurstModule,
    AccountsModule,
    SetAccountInfoModule,
    SetRewardRecipientModule,
    SetCommitmentModule,
    JuiceOrderModule,
    UnlockDialogModule
  ],
  exports: [
    MainComponent
  ],
  providers: [
    {
      provide: MatPaginatorIntl, deps: [I18nService],
      useFactory: (i18nService: I18nService) => new PaginatorI18n(i18nService).getPaginatorIntl()
    }
  ]
})

export class MainModule {
}
