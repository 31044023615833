<div style="margin: 20px 15px;" *ngIf="account.multiKeys && account.multiKeys.tron">

  <div class="account-info mb-16">
    <div class="account-info__field">
      <small class="info-title">{{'account'| i18n}}</small>
      <p class="info-detail" style="margin: 0;">{{account.multiKeys ? account.multiKeys.tron.address : ''}}</p>
    </div>
    <div class="account-info__field balance-info">
      <small class="info-title">{{'balance_available'| i18n}}</small>
      <div class="info-detail">{{ formatBalance(trxBalances) }} TRX</div>
    </div>
  </div>

  <mat-horizontal-stepper linear
                          [linear]="true"
                          [selectedIndex]="stepIndex"
                          class="hide-header"
  >
    <mat-step>
      <mat-form-field appearance="outline" floatLabel="always" class="eth-address">
        <mat-label>{{ 'address' | i18n }}</mat-label>
        <input matInput [(ngModel)]="recipientAddress" name="address">
        <ng-container>
          <input #file type="file" accept="image/*" (click)="resetFile($event)" (change)="parseQR()" id="{{fileId}}"
                 class="inputfile" capture="camera" [disabled]="isGettingData">
          <label for="{{fileId}}" class="camera-icon">
            <img width="30" height="30" src="./assets/icons/menu/icon-camera.png" alt="">
          </label>
        </ng-container>
      </mat-form-field>

      <amount-input (amountChange)="valueChange($event)"
                    [symbol]="symbol"
                    [amountValue]="amount"
                    [balances]="trxBalances"
                    [decimals]="decimals"
                    [account]="account"></amount-input>
      <p class="text-right changeTrx"  style="margin-top: -20px; font-size: 12px" matSuffix>
        ~ {{ changeTrx || '' }}
        {{this.settings?.currency.trx}}
      </p>

      <mat-form-field class="message-text password-field" floatLabel="always" appearance="outline">
        <mat-label>{{ 'enter_pin' | i18n }}<span style="color:red"> *</span></mat-label>
        <input type="password" matInput placeholder="{{'enter_pin' | i18n}}" [(ngModel)]="pin" name="pin">
      </mat-form-field>

      <div class="sp-font" style="display: flex; margin: 10px 0 20px 0;">
        <strong>{{'total' | i18n}}:&nbsp;</strong>
        <div style="display: flex; flex-direction: column;">
          <div>
            <span [class.error]="!hasSufficientBalanceTrx()">{{ getTotal() }} TRX</span>
          </div>
        </div>
      </div>

      <div class="send-button">
        <button class="{{!isGettingData && !!recipientAddress && !!amount && hasSufficientBalanceTrx() ? 'btn-common' : 'btn-common-disable'}}"
                [disabled]="isGettingData || !recipientAddress || !amount || !hasSufficientBalanceTrx()" (click)="nextStep()">
          {{ 'next' | i18n }}
          <mat-icon *ngIf="isGettingData" style="vertical-align: middle;">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>
        </button>
      </div>
    </mat-step>

    <mat-step>
      <div class="sp-font">
        <div><strong>{{ 'to_address' | i18n }}</strong></div>
        <div>{{ recipientAddress }}</div>
      </div>

      <div class="sp-font" style="display: flex; flex-direction: row; justify-content: space-between; margin-top: 10px;">
        <div>
          <div><strong>{{ 'amount' | i18n }}</strong></div>
          <div>{{ amount }} TRX (~ {{ changeTrx || '' }} {{this.settings?.currency.trx}})</div>
        </div>
      </div>

      <div class="sp-font" style="display: flex; flex-direction: row; justify-content: space-between; margin-top: 10px;">
        <div>
          <div><strong>{{ 'resources_consumed' | i18n }}</strong></div>
          <div>{{ getResourceConsumed() }}</div>
        </div>
        <div style="text-align: right;">
          <div><strong>{{ 'max_fee' | i18n }}</strong></div>
          <div>{{ maxFee }} TRX</div>
        </div>
      </div>

      <div class="sp-font" style="margin-bottom: 20px; color: #ED6D00; font-size: 14px;">{{ 'trx_usdt_fee_hint' | i18n }}</div>

      <div class="sp-font" style="display: flex; margin: 20px 0;">
        <strong>{{'total' | i18n}}:&nbsp;</strong>
        <div style="display: flex; flex-direction: column;">
          <div [class.error]="!hasSufficientBalanceTrx()">{{ getTotalTrx() }} TRX</div>
          <div *ngIf="!isRecipientActive">({{ 'in_activate_account_fee' | i18n }})</div>
        </div>
      </div>

      <div class="send-button">
        <button class="{{!isSending && !isGettingData ? 'btn-common' : 'btn-common-disable'}}"
                [disabled]="isSending || isGettingData"
                (click)="onCancel()">
          {{ 'back' | i18n }}
        </button>
        <button class="{{canSubmit() && !isSending && !isGettingData ? 'btn-common' : 'btn-common-disable'}}"
                [disabled]="!canSubmit() || isSending || isGettingData" (click)="onSubmit($event)">
          {{ 'send' | i18n }}
          <mat-icon *ngIf="isSending || isGettingData" style="vertical-align: middle;">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
