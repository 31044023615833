<div>

  <form (ngSubmit)="onSubmit($event);" #sendUsdtForm="ngForm" class="{{ isGettingData ? 'unconfirmed' : '' }}">
    <div class="account-info mb-8">
      <div class="account-info__field">
        <small class="info-title">{{'account'| i18n}}</small>
        <p class="pb-8 info-detail">{{account.multiKeys ? account.multiKeys.bnb.address : ''}}</p>
      </div>
      <div class="account-info__field balance-info">
        <small class="info-title">{{'balance_available'| i18n}}</small>
        <div class="info-detail">{{ formatBalance(balances) }} USDT</div>
        <div class="info-detail">{{ formatBalance(bnbBalances) }} BNB</div>
      </div>
    </div>

    <mat-form-field appearance="outline" floatLabel="always" class="eth-address">
      <mat-label>{{ 'address' | i18n }}</mat-label>
      <input matInput [(ngModel)]="recipientAddress" name="address" [disabled]="isGettingData || disableInputField" (input)="estimateGasUsed();">
      <ng-container>
        <input #file type="file" accept="image/*" (click)="resetFile($event)" (change)="parseQR()" id="{{fileId}}"
               class="inputfile" capture="camera" [disabled]="isGettingData || disableInputField">
        <label for="{{fileId}}" class="camera-icon">
          <img width="30" height="30" src="./assets/icons/menu/icon-camera.png" alt="">
        </label>
      </ng-container>
    </mat-form-field>

    <amount-input [disabled]="isGettingData || disableInputField"
                  (amountChange)="valueChange($event)"
                  [symbol]="symbol"
                  [amountValue]="amount"
                  [balances]="balances"
                  [fees]="0"
                  [decimals]="decimals"
                  [account]="account"></amount-input>
    <p class="text-right changeUsdt"  style="margin-top: -20px; font-size: 12px" matSuffix>
      ~ {{ changeUsdt || '' }}
      {{this.settings?.currency.usdt}}
    </p>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'max_fee' | i18n }}</mat-label>
      <input matInput [(ngModel)]="maxFee" name="gasPrice" disabled>
      <span class="mr-8 prefix" matSuffix>{{bnbSymbol}}</span>
    </mat-form-field>
    <div style="margin-top: -20px; margin-bottom: 20px; color: #ED6D00; font-size: 14px;">{{ 'eth_fee_hint' | i18n }}</div>

    <div style="color: #d73a49; text-align: center;"
         *ngIf="gas ? gas.index === estimateGas[0].index : false">{{ 'low_gas' | i18n }}</div>
    <div style="color: #219e37; text-align: center;"
         *ngIf="gas ? gas.index === estimateGas[1].index : false">{{ 'medium_gas' | i18n }}</div>
    <div style="color: #219e37; text-align: center;"
         *ngIf="gas ? gas.index === estimateGas[2].index : false">{{ 'high_gas' | i18n }}</div>

    <mat-radio-group class="estimate-gas-group" [(ngModel)]="gas" name="gas"
                     (ngModelChange)="onGasChanged()" [disabled]="isGettingData">
      <div>
        <mat-radio-button class="estimate-gas-item" *ngFor="let gasFee of estimateGas" [value]="gasFee"
                          [name]="gasFee.priority">
          {{ gasFee.priority }}
        </mat-radio-button>
      </div>
    </mat-radio-group>

    <div *ngIf="advanced">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'gas_limit' | i18n }}</mat-label>
        <input matInput [value]="gasLimit" name="gasLimit" disabled>
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'max_priority' | i18n }}</mat-label>
        <input matInput [value]="gas.value" name="maxPriority" disabled>
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'max_gas_fee' | i18n }}</mat-label>
        <input matInput [value]="maxGasFeeToWei()" name="maxGasFee" disabled>
      </mat-form-field>
    </div>

    <mat-form-field class="message-text password-field" floatLabel="always" appearance="outline">
      <mat-label>{{ 'enter_pin' | i18n }}<span style="color:red"> *</span></mat-label>
      <input type="password" matInput placeholder="{{'enter_pin' | i18n}}" [(ngModel)]="pin" name="pin">
    </mat-form-field>

    <div style="display: flex;">
      <strong>{{'total' | i18n}}:&nbsp;</strong>
      <div style="display: flex; flex-direction: column;">
        <div>
          <span [class.error]="!hasSufficientBalance()">{{ getTotal() }} USDT</span> -
          <a (click)="advanced = !advanced"><em>{{ advanced ? ("basic" | i18n) : "advanced" | i18n }}</em></a>
        </div>
        <div [class.error]="!hasSufficientBalanceBnb()">{{ getTotalBnb() }} BNB</div>
      </div>
    </div>

    <div class="send-button">
      <button class="{{canSubmit() && !isSending && !isGettingData ? 'btn-common' : 'btn-common-disable'}}"
              [disabled]="!canSubmit() || isSending || isGettingData" mat-raised-button mat-button color="primary">
        {{ 'send' | i18n }}
        <mat-icon *ngIf="isSending" style="vertical-align: middle;">
          <mat-spinner diameter="20"></mat-spinner>
        </mat-icon>
      </button>
    </div>
  </form>

</div>
