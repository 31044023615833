<!--<div id="balance-diagram-charts-wrapper" (mouseenter)="showPoints()" (mouseleave)="hidePoints()">-->
<div id="balance-diagram-charts-wrapper">
  <div class="main-widget">
    <div class="pb-16 blue-600">
      <div class="pl-16 balance-info">
        <div class="inline responsive">
          <div style="display: flex;" class="multi-coin-component">
            <div class="multi-coin-icon">
              <img src="assets/icons/multi-coin/home-frts.png" height="44" width="44" alt=""/>
            </div>
            <div>
              <div>
                <span class="total-amount pr-8 mb-0">{{ formatBalance(accountBalances.totalBalance.getSigna()) }} FRTS</span>
              </div>
              <ng-container style="margin-left: 10px;" *ngIf="isSelectingFrts || !priceFrts">
                <mat-spinner diameter="15"></mat-spinner>
              </ng-container>
              <div *ngIf="priceFrts && !isSelectingFrts" class="inline other-currency">
                <small
                  class="font-size-14 pr-8">{{ formatCurrency(calculateValue(priceFrts.priceBtc) | currency: '฿') }}</small>
                <span>/</span>
                <small
                  class="font-size-14 pl-8">{{ formatBalance(calculateValue(priceFrts.priceSelectedCurrency || '0')) }}</small>
                <mat-form-field class="currency" appearance="outline" floatLabel="always">
                  <mat-select (closed)="currencyInputFrts = ''; currencyListFrts = currencyListCopy;" [(ngModel)]="currencySelectedFrts" name="currency" disableOptionCentering
                              panelClass="myPanelClass myPanelClassCurrency" (selectionChange)="onCurrencyChange($event)">
                    <mat-form-field style="width: 96%; min-width: 80px; margin: 0 0 0 5px;" appearance="outline">
                      <input [(ngModel)]="currencyInputFrts" matInput name="keySearch" (keyup)="filterCurrency($event)" placeholder="{{ 'search' | i18n }}">
                    </mat-form-field>
                    <div class="currency-item" style="max-height: 190px; overflow: auto;">
                      <mat-option *ngFor="let currency of currencyListFrts" [value]="currency">
                        {{ currency.code }}
                      </mat-option>
                    </div>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="inline responsive">
          <small class="font-size-14 pr-8">{{"balance_available"| i18n}}
            : {{accountBalances.availableBalance.getSigna() | burstAmount}}</small>
          <small class="font-size-14">{{"balance_locked"| i18n}}
            : {{accountBalances.lockedBalance.getSigna() | burstAmount}}</small>
        </div>
      </div>

      <div style="display: flex; flex-wrap: wrap;">
        <div *ngIf="account.multiWallet && account.multiKeys.btc" class="pl-16 balance-info">
          <div class="inline responsive">
            <div style="display: flex;" class="multi-coin-component">
              <div class="multi-coin-icon">
                <img src="assets/icons/multi-coin/home-btc.png" height="44" width="44" alt=""/>
              </div>
              <div>
                <div>
                  <span class="total-amount pr-8 mb-0">{{ btcBalance ? formatBalance(btcBalance.toString()) : '0' }} BTC</span>
                </div>
                <ng-container style="margin-left: 10px;" *ngIf="isSelectingBtc || !priceBtc">
                  <mat-spinner diameter="15"></mat-spinner>
                </ng-container>
                <div *ngIf="priceBtc && !isSelectingBtc" class="inline other-currency">
                  <small
                    class="font-size-14 pr-8">{{ '฿ ' + (btcBalance ? formatBalance(btcBalance.toString()) : '0') }}</small>
                  <span>/</span>
                  <small
                    class="font-size-14 pl-8">{{ formatBalance(calculateValueBtc(priceBtc.priceSelectedCurrency)) }}</small>
                  <mat-form-field class="currency" appearance="outline" floatLabel="always">
                    <mat-select (closed)="currencyInputBtc = ''; currencyListBtc = currencyListCopy;" [(ngModel)]="currencySelectedBtc" name="currency" disableOptionCentering
                                panelClass="myPanelClass myPanelClassCurrency" (selectionChange)="onCurrencyChangeBtc($event)">
                      <mat-form-field style="width: 96%; min-width: 80px; margin: 0 0 0 5px;" appearance="outline">
                        <input [(ngModel)]="currencyInputBtc" matInput name="keySearch" (keyup)="filterCurrencyBtc($event)" placeholder="{{ 'search' | i18n }}">
                      </mat-form-field>
                      <div class="currency-item" style="max-height: 190px; overflow: auto;">
                        <mat-option *ngFor="let currency of currencyListBtc" [value]="currency">
                          {{ currency.code }}
                        </mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="account.multiWallet && account.multiKeys.eth" class="pl-16 balance-info">
          <div class="inline responsive">
            <div style="display: flex;" class="multi-coin-component">
              <div class="multi-coin-icon">
                <img src="assets/icons/multi-coin/home-eth.png" height="44" width="44" alt=""/>
              </div>
              <div>
                <div>
                  <span class="total-amount pr-8 mb-0">{{ formatBalance(ethBalance) }} ETH</span>
                </div>
                <ng-container style="margin-left: 10px;" *ngIf="isSelectingEth || !priceEth">
                  <mat-spinner diameter="15"></mat-spinner>
                </ng-container>
                <div *ngIf="priceEth && !isSelectingEth" class="inline other-currency">
                  <small
                    class="font-size-14 pr-8">{{ formatCurrency(calculateValueEth(priceEth.priceBtc) | currency: '฿') }}</small>
                  <span>/</span>
                  <small
                    class="font-size-14 pl-8">{{ formatBalance(calculateValueEth(priceEth.priceSelectedCurrency)) }}</small>
                  <mat-form-field class="currency" appearance="outline" floatLabel="always">
                    <mat-select (closed)="currencyInputEth = ''; currencyListEth = currencyListCopy;" [(ngModel)]="currencySelectedEth" name="currency" disableOptionCentering
                                panelClass="myPanelClass myPanelClassCurrency" (selectionChange)="onCurrencyChangeEth($event)">
                      <mat-form-field style="width: 96%; min-width: 80px; margin: 0 0 0 5px;" appearance="outline">
                        <input [(ngModel)]="currencyInputEth" matInput name="keySearch" (keyup)="filterCurrencyEth($event)" placeholder="{{ 'search' | i18n }}">
                      </mat-form-field>
                      <div class="currency-item" style="max-height: 190px; overflow: auto;">
                        <mat-option *ngFor="let currency of currencyListEth" [value]="currency">
                          {{ currency.code }}
                        </mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="account.multiWallet && account.multiKeys.eth" class="pl-16 balance-info">
          <div class="inline responsive">
            <div style="display: flex;" class="multi-coin-component">
              <div class="multi-coin-icon">
                <img src="assets/icons/multi-coin/home-usdt.png" height="44" width="44" alt=""/>
              </div>
              <div>
                <div>
                  <span class="total-amount pr-8 mb-0">{{ usdtBalance ? formatBalance(usdtBalance.toString()) : '0' }} USDT</span>
                </div>
                <ng-container style="margin-left: 10px;" *ngIf="isSelectingUsdt || !priceUsdt">
                  <mat-spinner diameter="15"></mat-spinner>
                </ng-container>
                <div *ngIf="priceUsdt && !isSelectingUsdt" class="inline other-currency">
                  <small
                    class="font-size-14 pr-8">{{ formatCurrency(calculateValueUsdt(priceUsdt.priceBtc) | currency: '฿') }}</small>
                  <span>/</span>
                  <small
                    class="font-size-14 pl-8">{{ formatBalance(calculateValueUsdt(priceUsdt.priceSelectedCurrency)) }}</small>
                  <mat-form-field class="currency" appearance="outline" floatLabel="always">
                    <mat-select (closed)="currencyInputUsdt = ''; currencyListUsdt = currencyListCopy;" [(ngModel)]="currencySelectedUsdt" name="currency" disableOptionCentering
                                panelClass="myPanelClass myPanelClassCurrency" (selectionChange)="onCurrencyChangeUsdt($event)">
                      <mat-form-field style="width: 96%; min-width: 80px; margin: 0 0 0 5px;" appearance="outline">
                        <input [(ngModel)]="currencyInputUsdt" matInput name="keySearch" (keyup)="filterCurrencyUsdt($event)" placeholder="{{ 'search' | i18n }}">
                      </mat-form-field>
                      <div class="currency-item" style="max-height: 190px; overflow: auto;">
                        <mat-option *ngFor="let currency of currencyListUsdt" [value]="currency">
                          {{ currency.code }}
                        </mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="account.multiWallet && account.multiKeys.tron" class="pl-16 balance-info">
          <div class="inline responsive">
            <div style="display: flex;" class="multi-coin-component">
              <div class="multi-coin-icon">
                <img src="assets/icons/multi-coin/home-trx.png" height="44" width="44" alt=""/>
              </div>
              <div>
                <div>
                  <span class="total-amount pr-8 mb-0">{{ trxBalance ? formatBalance(trxBalance.toString()) : '0' }} TRX</span>
                </div>
                <ng-container style="margin-left: 10px;" *ngIf="isSelectingTrx || !priceTrx">
                  <mat-spinner diameter="15"></mat-spinner>
                </ng-container>
                <div *ngIf="priceTrx && !isSelectingTrx" class="inline other-currency">
                  <small
                    class="font-size-14 pr-8">{{ formatCurrency(calculateValueTrx(priceTrx.priceBtc) | currency: '฿') }}</small>
                  <span>/</span>
                  <small
                    class="font-size-14 pl-8">{{ formatBalance(calculateValueTrx(priceTrx.priceSelectedCurrency)) }}</small>
                  <mat-form-field class="currency" appearance="outline" floatLabel="always">
                    <mat-select (closed)="currencyInputTrx = ''; currencyListTrx = currencyListCopy;" [(ngModel)]="currencySelectedTrx" name="currency" disableOptionCentering
                                panelClass="myPanelClass myPanelClassCurrency" (selectionChange)="onCurrencyChangeTrx($event)">
                      <mat-form-field style="width: 96%; min-width: 80px; margin: 0 0 0 5px;" appearance="outline">
                        <input [(ngModel)]="currencyInputTrx" matInput name="keySearch" (keyup)="filterCurrencyTrx($event)" placeholder="{{ 'search' | i18n }}">
                      </mat-form-field>
                      <div class="currency-item" style="max-height: 190px; overflow: auto;">
                        <mat-option *ngFor="let currency of currencyListTrx" [value]="currency">
                          {{ currency.code }}
                        </mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="account.multiWallet && account.multiKeys.tron" class="pl-16 balance-info">
          <div class="inline responsive">
            <div style="display: flex;" class="multi-coin-component">
              <div class="multi-coin-icon">
                <img src="assets/icons/multi-coin/home-trxusdt.png" height="44" width="44" alt=""/>
              </div>
              <div>
                <div>
                  <span class="total-amount pr-8 mb-0">{{ trxUsdtBalance ? formatBalance(trxUsdtBalance.toString()) : '0' }} USDT</span>
                </div>
                <ng-container style="margin-left: 10px;" *ngIf="isSelectingTrxUsdt || !priceTrxUsdt">
                  <mat-spinner diameter="15"></mat-spinner>
                </ng-container>
                <div *ngIf="priceTrxUsdt && !isSelectingTrxUsdt" class="inline other-currency">
                  <small
                    class="font-size-14 pr-8">{{ formatCurrency(calculateValueTrxUsdt(priceTrxUsdt.priceBtc) | currency: '฿') }}</small>
                  <span>/</span>
                  <small
                    class="font-size-14 pl-8">{{ formatBalance(calculateValueTrxUsdt(priceTrxUsdt.priceSelectedCurrency)) }}</small>
                  <mat-form-field class="currency" appearance="outline" floatLabel="always">
                    <mat-select (closed)="currencyInputTrxUsdt = ''; currencyListTrxUsdt = currencyListCopy;" [(ngModel)]="currencySelectedTrxUsdt" name="currency" disableOptionCentering
                                panelClass="myPanelClass myPanelClassCurrency" (selectionChange)="onCurrencyChangeTrxUsdt($event)">
                      <mat-form-field style="width: 96%; min-width: 80px; margin: 0 0 0 5px;" appearance="outline">
                        <input [(ngModel)]="currencyInputTrxUsdt" matInput name="keySearch" (keyup)="filterCurrencyTrxUsdt($event)" placeholder="{{ 'search' | i18n }}">
                      </mat-form-field>
                      <div class="currency-item" style="max-height: 190px; overflow: auto;">
                        <mat-option *ngFor="let currency of currencyListTrxUsdt" [value]="currency">
                          {{ currency.code }}
                        </mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Bnb coin -->
        <div *ngIf="account.multiWallet && account.multiKeys.bnb" class="pl-16 balance-info">
          <div class="inline responsive">
            <div style="display: flex;" class="multi-coin-component">
              <div class="multi-coin-icon">
                <img src="assets/icons/multi-coin/home-bnb.png" height="44" width="44" alt="" />
              </div>
              <div>
                <div>
                  <span class="total-amount pr-8 mb-0">{{ bnbBalance ? formatBalance(bnbBalance.toString()) : '0' }} BNB</span>
                </div>
                <ng-container style="margin-left: 10px;" *ngIf="isSelectingBnb || !priceBnb">
                  <mat-spinner diameter="15"></mat-spinner>
                </ng-container>
                <div *ngIf="priceBnb && !isSelectingBnb" class="inline other-currency">
                  <small class="font-size-14 pr-8">{{ formatCurrency(calculateValueBnb(priceBnb.priceBtc) | currency: '฿')
                    }}</small>
                  <span>/</span>
                  <small class="font-size-14 pl-8">{{ formatBalance(calculateValueBnb(priceBnb.priceSelectedCurrency))
                    }}</small>
                  <mat-form-field class="currency" appearance="outline" floatLabel="always">
                    <mat-select (closed)="currencyInputBnb = ''; currencyListBnb = currencyListCopy;"
                      [(ngModel)]="currencySelectedBnb" name="currency" disableOptionCentering
                      panelClass="myPanelClass myPanelClassCurrency" (selectionChange)="onCurrencyChangeBnb($event)">
                      <mat-form-field style="width: 96%; min-width: 80px; margin: 0 0 0 5px;" appearance="outline">
                        <input [(ngModel)]="currencyInputBnb" matInput name="keySearch" (keyup)="filterCurrencyBnb($event)"
                          placeholder="{{ 'search' | i18n }}">
                      </mat-form-field>
                      <div class="currency-item" style="max-height: 190px; overflow: auto;">
                        <mat-option *ngFor="let currency of currencyListBnb" [value]="currency">
                          {{ currency.code }}
                        </mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Usdt-bnb coin -->
        <div *ngIf="account.multiWallet && account.multiKeys.bnb" class="pl-16 balance-info">
          <div class="inline responsive">
            <div style="display: flex;" class="multi-coin-component">
              <div class="multi-coin-icon">
                <img src="assets/icons/multi-coin/home-bnb-usdt.png" height="44" width="44" alt="" />
              </div>
              <div>
                <div>
                  <span class="total-amount pr-8 mb-0">{{ bnbUsdtBalance ? formatBalance(bnbUsdtBalance.toString()) : '0' }}
                    USDT</span>
                </div>
                <ng-container style="margin-left: 10px;" *ngIf="isSelectingBnbUsdt || !priceBnbUsdt">
                  <mat-spinner diameter="15"></mat-spinner>
                </ng-container>
                <div *ngIf="priceBnbUsdt && !isSelectingBnbUsdt" class="inline other-currency">
                  <small class="font-size-14 pr-8">{{ formatCurrency(calculateValueBnbUsdt(priceBnbUsdt.priceBtc) | currency:
                    '฿') }}</small>
                  <span>/</span>
                  <small class="font-size-14 pl-8">{{ formatBalance(calculateValueBnbUsdt(priceBnbUsdt.priceSelectedCurrency))
                    }}</small>
                  <mat-form-field class="currency" appearance="outline" floatLabel="always">
                    <mat-select (closed)="currencyInputBnbUsdt = ''; currencyListBnbUsdt = currencyListCopy;"
                      [(ngModel)]="currencySelectedBnbUsdt" name="currency" disableOptionCentering
                      panelClass="myPanelClass myPanelClassCurrency" (selectionChange)="onCurrencyChangeBnbUsdt($event)">
                      <mat-form-field style="width: 96%; min-width: 80px; margin: 0 0 0 5px;" appearance="outline">
                        <input [(ngModel)]="currencyInputBnbUsdt" matInput name="keySearch"
                          (keyup)="filterCurrencyBnbUsdt($event)" placeholder="{{ 'search' | i18n }}">
                      </mat-form-field>
                      <div class="currency-item" style="max-height: 190px; overflow: auto;">
                        <mat-option *ngFor="let currency of currencyListBnbUsdt" [value]="currency">
                          {{ currency.code }}
                        </mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div *ngIf="account.transactions.length">-->
<!--        <div class="position-relative">-->
<!--          <canvas #myCanvas-->
<!--                  baseChart-->
<!--                  chartType="line"-->
<!--                  [datasets]="chart.datasets"-->
<!--                  [labels]="chart.labels"-->
<!--                  [colors]="chart.colors"-->
<!--                  [options]="chart.options"-->
<!--          >-->
<!--          </canvas>-->
<!--        </div>-->
<!--        <small class="pl-16">{{"recent_transactions_since" | i18n}}&nbsp;{{firstDate}}</small>-->
<!--      </div>-->
<!--      <div class="no-transactions" *ngIf="!account.transactions.length">-->
<!--        <h1 class="align-center">{{"dashboard_welcome_title" | i18n}}</h1>-->
<!--      </div>-->
    </div>
  </div>
</div>
